@import '../../../base-components/StudioTextField/shared';

.dynamic-shape-field {
  &__row {
    display: flex;
    align-items: center;
  }

  &__box-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  &__box {
    width: 2.5em;
    margin-right: 0.3em;
  }

  @include MuiInputOverrides;

  & .MuiInput-input {
    text-align: center;
    padding: 0.75em 0;
  }

  &__tooltip {
    margin-left: auto;
  }

  &__tooltip--hidden {
    visibility: hidden;
  }

  &__adjust {
    &.MuiIconButton-root {
      display: inline-block;
      margin-top: 0.5em;
    }

    & svg {
      font-size: 1rem;
    }
  }
}
