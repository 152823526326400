.analytics-deploy-settings {
  &.MuiAccordion-root {
    margin-top: 0;
    min-width: 260px;
    max-height: 100%;
    padding-bottom: 2em;
  }

  &__title-container.MuiAccordionSummary-root {
    background-color: var(--purple-4);
    min-height: unset;

    .MuiAccordionSummary-content {
      margin: 0;

      h4 {
        margin: 0;
      }
    }
  }

  .MuiCollapse-root {
    max-height: 100%;
    overflow: auto;
  }

  &__body_container {
    background-color: var(--greyscale1);
    max-height: 100%;

    input {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
