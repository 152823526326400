@import './shared';

.studio-text-field {
  @include MuiInputOverrides;

  &__row {
    display: flex;
    justify-content: space-between;

    & .MuiTextField-root {
      flex: 1;
    }

    & > *:nth-child(n + 2) {
      margin-left: 0.5em;
    }
  }

  &__container {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__tooltip {
    position: relative;
    cursor: pointer;

    /*
    TODO: find a better way to align the tooltip to the 
    input. Currently a magic number is being used because
    material ui does not provide a great option for customizing
    the TextField layout. If the height of the TextField is 
    changed, the tooltip will no longer be aligned to the input
  */
    top: 1.7em;

    &--hidden {
      visibility: hidden;
    }
  }

  &__field-size {
    float: right;
    color: var(--greyscale6);
    font-size: small;
  }
}
