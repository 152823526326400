@import '../../../styles/mixins.scss';
@import './variables';

$transition-duration: 0.1s;
$transition-type: ease-out;

$center-margin: $node-width / 2 - 18;
.flow-editor {
  width: 100%;
  height: 100%;
  .socket {
    z-index: 2;
    border-radius: 15px;
    border: 0px;
    width: $node-connector-size;
    height: $node-connector-size;
    background: rgba($color: #000000, $alpha: 0);
    @include transition(all, $transition-duration, $transition-type);
    &:hover {
      background: rgba($color: #000000, $alpha: 0.1);
    }
    &.input,
    &.output {
      &::before {
        position: absolute;
        content: 'oo';
        font-size: 8px;
        color: rgba($color: #000000, $alpha: 0);
        border-radius: 15px;
        width: 8px;
        height: 8px;
      }
    }
    &.input,
    &.output {
      &.img_bayer::before {
        background: #66c2a5;
      }
      &.img_rgb::before {
        background: #f5d451;
      }
      &.img_yuv::before {
        background: #8da0cb;
      }
    }
    &.input {
      margin-left: $center-margin + 4;
      margin-top: -$node-v-pos;
      &::before {
        right: 18px;
        bottom: 28px;
      }
    }
    &.output {
      margin-right: $center-margin;
      margin-top: $node-v-pos;
      &::before {
        left: 49.2%;
        bottom: 16px;
      }
    }
    &.invisible_socket {
      display: none;
    }
  }

  .context-menu {
    min-width: 200px;
  }
  .subitems {
    opacity: 1;
    min-width: 250px;
  }
}
