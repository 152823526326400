.integration-dialog-base {
  .MuiPaper-root {
    box-sizing: border-box;
    min-width: 25em;
    width: 70vw;
    height: 80vh;
    background-color: var(--greyscale1);
  }

  @media screen and (min-width: 1024px) {
    .MuiPaper-root {
      width: 41vw;
    }
  }

  & .dynamic-form__body {
    padding: 1em 4em;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    background-color: var(--white);
    padding: 1em;
    & button {
      margin-left: 1em;
    }
  }

  &__title {
    border-bottom: 1px solid #dfdfe0;
    padding-bottom: 0.5em;
    margin: 4rem 2.5rem 0 4rem;
  }
}
