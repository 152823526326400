.studio-checkbox {
  &__tooltip {
    align-self: center;
    color: var(--greyscale5);
  }

  &.MuiFormGroup-root {
    flex-direction: row;
    justify-content: space-between;
  }

  & .MuiCheckbox-root {
    margin: 0.125em;
  }

  & .MuiCheckbox-root.Mui-focusVisible,
  & .MuiCheckbox-root:hover {
    background-color: #ebebec;
  }

  & .MuiCheckbox-root:active {
    background-color: #dfdfdf;
  }

  & .MuiCheckbox-root.Mui-checked {
    color: var(--greyscale7);
  }

  & .MuiFormControlLabel-label {
    color: var(--greyscale7);
    user-select: none;
  }

  & .MuiCheckbox-root.Mui-checked + .MuiFormControlLabel-label {
    color: var(--secondary2);
    font-weight: bold;
  }

  & .Mui-disabled {
    &.MuiFormControlLabel-label,
    &.MuiCheckbox-root {
      color: var(--greyscale2);
    }
  }

  &__tooltip--hidden {
    visibility: hidden;
  }
}
