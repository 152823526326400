@import '../vars.scss';

.detection-view {
  &__thumbnail_container {
    min-width: 240px;
    max-width: 240px; // We may need this ro programatically adjust later on when we have the view adjuster
    padding: 0.5em;
    margin-right: $layout-spacer;
    display: flex;
    flex-direction: column;
  }
  &__thumbnail__no_result {
    margin: 0.5em 1em;
  }
  &__thumbnail_toolbar {
    padding: 0;
  }
  &__thumbnail {
    margin: 0.25em;
    border-width: 4px;
    border-color: white;
    &_annotation_count {
      position: absolute;
      bottom: 0;
      right: 0;
      background: black;
      min-width: 1em;
      min-height: 1em;
      color: white;
      text-align: center;
      padding: 0.1em 0.5em;
      font-weight: bolder;
    }
    &_list_container {
      flex-grow: 1;
    }
    &.selected {
      border-width: 4px;
      border-color: var(--primary3);
    }
    &_content,
    &_action_area {
      padding: 0;
    }
    &_search_input {
      & input {
        padding: 0 !important;
      }
      & .MuiInput-root {
        border: none !important;
        margin-top: 0;
      }
    }
  }
  &__thumbnail_container_list {
    min-width: 224px;
  }
  &__thumbnail_list {
    min-width: 200px;
    width: 100%;
    border-width: 1px;
    border-color: var(--greyscale1);
    &_image {
      max-width: 30px;
      margin: 0;
    }
    &.selected {
      border-color: #401266;
    }
    margin-bottom: 1px;
    &_content {
      display: flex;
      padding: 0.25em;
      & > h5 {
        padding-left: 0.5em;
        margin: 0;
        color: var(--greyscale5);
        margin: 0.2em 0 0;
        font-size: 0.8rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
