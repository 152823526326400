.subheader-button {
  min-width: 72px;
  height: 32px;
  font-size: 14px;
  letter-spacing: 0.32px;
  line-height: 16px;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  padding: 0;
  color: var(--white);
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subheader-button > div {
  padding: 0 8px;
}
.subheader-button-holder {
  float: left;
  display: flex;
  height: 32px;
  font-size: 14px;
  letter-spacing: 0.32px;
  line-height: 16px;
  color: var(--white);
  font-weight: 300;
}
.leftBtns > p {
  float: left;
  display: inline-block;
  line-height: 0;
  color: var(--white);
  line-height: 0px;
}
.subheader-button .buttonIcon {
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.subheader-button .text-bold {
  font-weight: bold;
}

.subheader-button:hover {
  background: var(--elementDarkHover);
}
.subheader-button:focus {
  box-shadow: 0 0 0 2px var(--white);
  outline: none;
}
.subheader-button:active {
  background: var(--elementDarkActive);
  box-shadow: none;
}
.subheader-button:disabled {
  color: var(--greyscale7);
  cursor: default;
}
.subheader-button:disabled:focus {
  box-shadow: 0 0 0 2px var(--greyscale7);
}

.subheader-button.pum-launcher-bold {
  font-weight: 600;
}
.subheader-button.pum-launcher-bold.open {
  box-shadow: none;
  background: var(--elementDarkActive);
}
