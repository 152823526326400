.user-details-info {
  .edge-installer-download {
    padding: 12px 16px 12px 16px;
    background: var(--white);
    border: 1px solid var(--greyscale3);
    color: var(--greyscale7);
    box-shadow: none;
  }
  .edge-installer-download:hover {
    border: 1px solid var(--greyscale2);
    color: var(--greyscale5);
  }
  .user-details-button,
  .deploy-action-button {
    padding-left: 0.25em;
  }
  .horizontal-spacer {
    margin-left: 10px;
  }
  .vertical-spacer {
    margin-bottom: 40px;
  }
}
