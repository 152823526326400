.legend-grid-container {
  display: flex;
  height: 100%;
  overflow-y: auto;
}

.inference-grid-container {
  display: flex;
  height: 100%;
  overflow-y: auto;

  .title {
    margin: 40px 0 !important;
  }
  .view-grid {
    // TODO: Need to import this as extended style since its used on grid views
    flex: 1 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    justify-items: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    -webkit-align-content: flex-start;
    padding: 0;
  }
}
.segmentation-result {
  &__image-card {
    width: 100%;
  }

  &__no-results {
    margin: 1em 0;
  }
}
