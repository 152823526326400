.no-deployment {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  height: 100%;
  text-align: center;
  white-space: pre-wrap;

  &__message {
    margin-top: 36px;
  }
}
