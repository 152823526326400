.studio-content-layout {
  display: flex;
  flex-flow: column;
  height: 100%;

  & header.row {
    flex: 0 1 auto;
    /* The above is shorthand for:
    flex-grow: 0,
    flex-shrink: 1,
    flex-basis: auto
    */
  }
  & section.row {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  & .footer.row {
    flex: 0 1 40px;
  }
}
