.deploy-status {
  & > * {
    margin-right: 10px;
  }

  &__bulb {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--uiGreen3);

    &--stopping {
      background-color: var(--uiRed2);
    }

    &--starting {
      background-color: var(--uiYellow1);
    }

    &--empty {
      display: none;
    }
  }
}
