.detection-view__thumbnail_filter_menu {
  h6 {
    margin: 0;
  }
  ul {
    display: flex;
    flex-direction: column;
  }
  .MuiToolbar-gutters,
  legend,
  .MuiFormGroup-root {
    padding-left: 1em;
    padding-right: 1em;
  }
}
