.modify-isp-form {
  padding-top: 1em;
  &__container {
    width: 430px !important;
    padding: 3em 2em 2em 2em;
  }
  &__name {
    margin-top: 1em;
    input {
      font-size: 26px;
    }
  }
  input {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }
  button.form-button,
  .delete-button {
    margin-left: 2em;
  }
  .delete-button {
    border: none;
  }
  &__buttons {
    display: flex;
    align-items: center;
  }
}
