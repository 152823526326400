.runtime-summary {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em;

  &__card-content {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    background: var(--greyscale1);
  }

  &__info {
    flex-basis: 18em;
    flex-shrink: 0;
  }

  &__name {
    font-size: 24px;
    font-weight: bold;
  }

  &__stats {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
