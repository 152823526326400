.reset-password__tooltip {
  text-align: center;
}

.reset-password__tooltip-text {
  color: var(--white);
  font-size: 14px;
  line-height: 1em;
}

.reset-password__tooltip-text--fade {
  color: var(--greyscale4);
}
