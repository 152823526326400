.studio-console {
  z-index: 10000;
  &__container {
    background: var(--greyscale2);
    min-height: 300px;
    max-height: 300px;
  }
  &__toolbar,
  &__title {
    padding: 0.5em 3em 0.5em 0.5em;
  }
  &__toolbar {
    display: flex;
    flex-wrap: wrap;
    row-gap: 7px;
  }
  &__spacer {
    flex-grow: 1;
    margin: 0 10px;
  }
  // Temp styling. Will replace this part with a virtualized table
  &__message {
    font-size: 0.87em;
    background: var(--greyscale1);
    width: 100%;
    min-height: 232px;
    max-height: 232px;
    height: 100%;
    overflow-y: auto;
    border: 1px solid gray;
    padding: 0 1em;
    &_log {
      color: var(--greyscale4);
      padding: 0.5em 0;
    }
    &_log_button {
      margin: 0.5em 0;
    }
  }
  &__message_type,
  &__method_type {
    border: 1px solid var(--greyscale4);
    display: inline-block;
    padding: 2px;
    margin: 3px;
    text-transform: uppercase;
    border-radius: 3px;
    font-size: 10px;
    font-weight: bold;
  }
  &__message_time {
    line-height: 1.5em;
  }
  &__message_type.error {
    background-color: var(--uiRed1);
    color: white;
  }
  &__message_type.warn {
    background-color: rgba(223, 197, 86, 1);
    color: white;
  }
  &__method_type {
    background-color: var(--greyscale4);
    color: white;
  }
  &__message_url {
    font-style: italic;
    color: var(--greyscale6);
    padding: 2px;
  }
  &__message_row {
    border-bottom: 1px solid var(--greyscale2);
    padding: 7px 0;
    // margin-bottom: 5px;
  }

  &__from__date {
    margin-right: 10px;
    width: max-content;

    &.studio-select .MuiInput-input {
      padding-right: 1.5rem;
    }
  }
}
