.detection-result {
  display: flex;
  flex: 1;
  height: 100%;
  overflow: hidden;
}

.detection-result__sidebar {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  max-width: 350px;
  overflow-y: auto;
  border: 1px solid var(--greyscale3);
}

.detection-result__review {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto;
  align-content: flex-start;
  border: 1px solid var(--greyscale3);
  margin-left: 14px;
}

.detection-result__image--selected img {
  border: 4px solid var(--primary1);
}

.detection-result__stats {
  margin-top: 1em;
  width: 200px;
}
