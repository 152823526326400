@import '../../../styles/mixins.scss';
$transition-duration: 0.25s;
$transition-type: ease-out;
$border-raduis: 0.25em !important;

$color-error: #faf1f0;
$color-gradient-error: #fff1f1;

$color-bayer: #e7fce7;
$color-gradient-bayer: #dafadc;

$color-bayer-rgb: #f2f3fc;
$color-gradient-bayer-rgb: #eaedff;

$color-rgb: #fdf8e5;
$color-gradient-rgb: #fdf7e2;

$color-rgb-yuv: #e4f5fb;
$color-gradient-rgb-yuv: #dff3fa;

$color-yuv: #f8f0fe;
$color-gradient-yuv: #f7ebff;

$color-yuv-rgb: #f2f5b6;
$color-gradient-yuv-rgb: #f0ffc2;

@mixin moduleBg($color) {
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 30%, $color 100%);
}
.flowgraph-node {
  position: relative;
  min-width: 300px;
  max-width: 300px;
  border-radius: 0.5em;
  border: var(--border);
  border-color: transparent;
  @include transition(border-color, $transition-duration, $transition-type);
  border-color: rgba(255, 255, 255, 0.8) !important;

  &.selected {
    border-color: var(--secondary2) !important;
    z-index: 1;
  }
  &.error {
    border-color: var(--uiRed1) !important;
  }
  &__expansion {
    margin: 4px !important;
    box-shadow: var(--shadow1) !important;
    background: rgb(255, 255, 255);
    border-radius: $border-raduis;
  }
  &.error &__expansion {
    @include moduleBg($color-gradient-error);
  }
  &__summary.error {
    background-color: $color-error;
  }

  &__summary.bayer {
    background-color: $color-bayer;
  }
  &.bayer &__expansion {
    @include moduleBg($color-gradient-bayer);
  }
  &__summary.bayer_rgb {
    background-color: $color-bayer-rgb;
  }
  &.bayer_rgb &__expansion {
    @include moduleBg($color-gradient-bayer-rgb);
  }
  &__summary.rgb {
    background-color: $color-rgb;
  }
  &.rgb &__expansion {
    @include moduleBg($color-gradient-rgb);
  }
  &__summary.rgb_yuv {
    background-color: $color-rgb-yuv;
  }
  &.rgb_yuv &__expansion {
    @include moduleBg($color-gradient-rgb-yuv);
  }
  &__summary.yuv {
    background-color: $color-yuv;
  }
  &.yuv &__expansion {
    @include moduleBg($color-gradient-yuv);
  }
  &__summary.yuv_rgb {
    background-color: $color-yuv-rgb;
  }
  &.yuv_rgb &__expansion {
    @include moduleBg($color-gradient-yuv-rgb);
  }

  &__delete_button {
    position: absolute;
    top: 12px;
    right: 5px;
    color: rgba(0, 0, 0, 0.15);
    z-index: 2;
    &:hover {
      color: rgba(0, 0, 0, 0.45);
    }
  }
  &__summary {
    height: 65px;
    max-height: 65px;
    padding: 0 !important;
    border-radius: $border-raduis;
    flex-direction: row-reverse;
    .MuiExpansionPanelSummary-content {
      flex-direction: column;
    }
    small {
      font-size: 12px;
    }
    h7 {
      font-size: 16px;
      font-weight: 600;
    }
    .MuiExpansionPanelSummary-expandIcon {
      margin: 0;
    }
  }
  &__sockets {
    z-index: 2;
  }
  &__socket {
    position: absolute;
    top: 14px;
    z-index: 1;
    &.output {
      text-align: right;
      width: 100%;
    }
  }
  &__detail {
    padding-top: 1em !important;
    padding-bottom: 0 !important;
    flex-direction: column;
  }
}

// Direct style changes to 3rd Party library
.flow-editor {
  .connection {
    margin-top: 16px;
    .main-path {
      stroke: #7b7d99;
    }
  }
}
