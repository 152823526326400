.test-inference-results__images {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: var(--greyscale1);
}

.test-inference-results__accuracy {
  margin: 0 auto;
}

.test-inference-results__accordion,
.test-inference-results__accordion.Mui-expanded {
  padding: 0 2em;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: inherit;

  .MuiAccordionSummary-root {
    flex-direction: row;
    justify-content: space-between;
  }

  .MuiAccordionSummary-content {
    font-weight: 600;
    flex-grow: unset;
  }
}

.test-inference-results__field {
  padding: 1em;
}

.test-inference-results__empty {
  color: var(--greyscale5);
  margin-left: 1em;
}

.test-inference-results__fetching-streaming-inference-waiting {
  height: 100%;
  justify-content: center;
  width: 100%;

  h4 {
    margin-bottom: 0;
  }

  p {
    margin-top: 4px;
  }
}

.test-inference-results__form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
}

.test-inference-results__search {
  position: sticky;
  top: 0;
  background: var(--greyscale1);
  flex-grow: 1;
  z-index: 1;
}

.test-inference-results__video-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 2em;
  width: 100%;
}

.test-inference-results__video {
  display: flex;
  flex-direction: column;
}

.test-inference-results__video-main {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;

  & > * {
    background-color: var(--greyscale1);
  }
}

.test-inference-results__full-size-container {
  display: flex;
  flex-grow: 1;
  width: 100%;
}
