.stage-space {
  position: relative;
  background-image: url('../../assets/images/ruler.png');
  background-repeat: repeat;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.stage-waiting {
  position: relative;
  top: calc(50% - 87px);
  left: 5px;
}

.stage-waiting .waiting {
  margin-right: 9px;
}
