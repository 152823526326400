.isp-form {
  &__form-control {
    flex-grow: 1;
    margin-top: 0;
    margin-bottom: 1em;
    .MuiInputBase-root {
      background-color: white;
    }

    .MuiInput-input {
      height: 1.5em;
      line-height: 1.5;
      padding: 0.75em 1em;
    }
  }
  &__wh_divider {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--greyscale4);
  }
  &__tooltip {
    margin: auto 0.5em;
  }
}
