.onnx-runtime {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  width: 100%;

  &__video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    video,
    canvas {
      width: 416px;
      height: 416px;
    }

    video {
      min-height: 100%;
      width: auto;
    }

    canvas {
      position: absolute;
    }
  }
}
