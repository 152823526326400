.project-form-container {
  .content {
    min-height: 400px;
  }
  #name {
    font-size: 32px !important;
  }

  &__loading {
    margin-top: 2em;
  }
}
