@import '../../styles/mixins.scss';

.image-viewer-header {
  &__left,
  &__right {
    @include center-align;
  }
  &__left {
    justify-content: flex-start;
  }
  &__right {
    justify-content: flex-end;
  }
}
