.studio-grid-card {
  width: 20.5em;
  margin: 0.5em;
  flex-flow: column nowrap;
  box-shadow: var(--shadow0);
  cursor: pointer;
  transition: box-shadow 0.3s;
  transition-timing-function: var(--timing);
  overflow: hidden;

  &__container {
    position: relative;
  }

  &:hover {
    box-shadow: var(--shadow2);
  }

  &__image {
    width: 20.5em;
    height: 16em;
    background: var(--tertiaryActive);
    overflow: hidden;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__body {
    height: 7em;
  }
}
