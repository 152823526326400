.data-viewer2 {
  margin: 1em;
  color: var(--greyscale6);
  font-family: 'Proxima Nova';

  &__all-labels {
    color: var(--primary1);
    font-size: 14px;
    line-height: 1.1428;
    font-weight: 600;

    svg {
      margin-right: 10px;
    }
  }

  & &__icon-button {
    align-items: center;
    margin-left: 0.25em;
  }

  &__back-button {
    box-shadow: var(--shadow2);
    background: white;
    padding: 0.5em 2em;
  }

  &__content {
    margin: 1em 0;

    > * {
      margin: 1em 0;
    }
  }

  &__current-label {
    color: var(--primary1);
    font-family: 'ITC Avant Garde Gothic Std';
    font-size: 26px;
    line-height: 1.6923;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    > * {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  &__controls {
    > * + * {
      margin-left: 0.5em;
    }
  }

  &__label {
    display: flex;
  }

  &__pagination {
    margin: 1em 0;
  }

  &__pagination-container {
    display: flex;
    justify-content: center;
  }

  &__search-icon {
    font-size: 26px;
    line-height: 32px;
    margin-right: 8px;
    margin-bottom: 9px;
    opacity: 80%;
  }

  &__tab {
    &.MuiTab-root {
      width: 264px;
    }
  }

  &__tab-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__tab-title {
    align-self: flex-start;
    font-size: 1.25rem;
  }

  &__tab-stats {
    display: flex;
    justify-content: space-between;
  }

  &__tabs {
    margin-right: auto;

    @media (max-width: 792px) {
      width: 100%;
    }

    & .MuiTab-root {
      background-color: #d8d8d8;
    }

    & .MuiTab-wrapper > svg {
      margin-right: 0.25em;
    }

    & .MuiTab-root.Mui-selected {
      background-color: var(--primary1);
      color: white;
    }
  }

  &__viewer {
    display: flex;
  }
}
