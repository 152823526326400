.video-stream {
  &__video-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  &__remote {
    width: 640px;
    height: 480px;
    background-color: #000;
  }

  &__user {
    width: 320px;
    height: 240px;
    background-color: #000;
    margin-top: 1em;
  }

  &__retry {
    min-width: auto;
  }
}
