.create-new-project {
  background: url('../../../assets/images/new-project-bkg.svg') no-repeat 350px 0 / cover,
    #ffffff;
}

.create-new-project:active {
  box-shadow: none;
}

.new-project-icon {
  background: url('../../../assets/images/add.svg') no-repeat 50%;
}
