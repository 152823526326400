.stage-assistant-form {
  color: var(--black);

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 2em;
  }

  &__actions :not(:first-child) {
    margin-left: 2em;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    font: 18px 'Proxima Nova';
    font-weight: bold;
    letter-spacing: 0.41px;
    line-height: 1.555555;
    margin: 0;
    padding: 2em 2em 1em;
  }

  &__alert {
    margin: 0 2em;
  }

  &__form {
    padding: 0 2em 2em;
  }

  &__message {
    padding: 0 3em;
  }
}
