$max-content-height: 450px;

.isp-form-dialog {
  &__button_action {
    display: flex;
    justify-content: flex-end;
    & > :not(:first-child) {
      margin-left: 1em;
    }
    button {
      width: 176px;
    }
  }
}
