.stage-panel {
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  transition: all 0.5s ease;
  min-width: 420px;
  max-width: 420px;
  position: relative;
}

.stage-panel div.stage-panel-outline {
  z-index: 3;
  flex: none;
  margin: -128px 25px -30px 25px;
  border-radius: 16px;
  opacity: 0;
  height: 0;
}

.stage-panel div.line {
  z-index: 2;
  flex: none;
  border: 13px solid #e8e8e8;
  margin-top: -143px;
}

.line-first {
  border-radius: 13px 0 0 13px;
  margin-left: 18px;
}

.line-last {
  border-radius: 0 13px 13px 0;
  margin-right: 18px;
}

.stage-panel-filler {
  height: 20px;
}

.stage-panel-content {
  z-index: 4;
  height: 240px;
  margin: 0 30px;
  box-sizing: border-box;
  border-radius: 12px;
  transition: background-color 0.5s ease-out;
}

.locked-content {
  display: none;
}

.locked-content .info-icon-locked {
  color: var(--greyscale3);
}

.locked-content .info-icon-active {
  color: var(--primary1Hover);
}

.stage-panel-content.locked {
  background-color: #f5f5f5;
  border: 2px solid #e8e8e8;
}

.stage-panel-content.locked .locked-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 2em;
}

.inactive-content {
  background-color: var(--greyscale1);
  color: var(--greyscale3);
}

.inactive-container {
  border: 1px dashed #dcdcdc;
  border-radius: 8px;
  text-align: center;
  height: 0;
  opacity: 0;
  margin: 0;
  transition: opacity 0.25s ease-out, height 0s linear 0.25s, margin 0s linear 0.25s;
}

.stage-panel-content.inactive .inactive-container {
  height: 222px;
  opacity: 1;
  margin: 8px;
  transition: height 0s linear 0.25s, margin 0s linear 0.25s, opacity 0.25s ease-in 0.25s;
}

.inactive-container .form-button {
  margin-top: 77px;
  background-color: #f5f5f5;
  width: 288px;
}

.stage-panel-content.inactive .inactive-content,
.stage-panel-content.active .active-content {
  height: 240px;
  opacity: 1;
  transition: height 0s linear 0.25s, opacity 0.25s ease-in 0.25s;
}

.active-container {
  border: 1px dashed #dcdcdc;
  border-radius: 8px;
  height: 0;
  opacity: 0;
  text-align: center;
  margin: 0;
  transition: opacity 0.25s ease-out, margin 0s linear 0.25s, height 0s linear 0.25s;
}

.stage-panel-content.active .active-container {
  height: 222px;
  opacity: 1;
  margin: 8px;
  transition: height 0s linear 0.25s, margin 0s linear 0.25s, opacity 0.25s ease-in 0.25s;
}

.active-container .form-button {
  margin-top: 158px;
  width: 250px;
}

.inactive-content,
.active-content,
.finished-content,
.in-progress-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 0;
  opacity: 0;
  transition: opacity 0.25s ease-out, height 0s linear 0.25s;
  overflow: hidden;
}

.in-progress-content {
  text-align: center;
}

.stage-panel-content.inactive,
.stage-panel-content.active,
.stage-panel-content.finished {
  background-color: var(--greyscale1);
}

.stage-panel-content.in-progress {
  background-color: white;
}

.stage-panel-content.inactive {
  color: var(--greyscale3);
}

.stage-panel-content.active,
.stage-panel-content.finished {
  color: black;
}

.stage-panel-content.focused {
  box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.1);
}

.stage-panel-content.finished .finished-content,
.stage-panel-content.in-progress .in-progress-content {
  height: 240px;
  opacity: 1;
  transition: height 0s linear 0.25s, opacity 0.25s ease-in 0.25s;
}

.finished-content .panel-title,
.in-progress-content .panel-title {
  min-height: 0;
  opacity: 0;
  transition: opacity 0.25s ease-out, min-height 0s linear 0.25s;
}

.stage-panel-content.finished .finished-content .panel-title {
  min-height: 68px;
  opacity: 1;
  transition: min-height 0s linear 0.25s, opacity 0.25s ease-in 0.25s;
  position: relative;
  margin-right: 1px;
}

.stage-panel-content.in-progress .in-progress-content .panel-title {
  min-height: 78px;
  opacity: 1;
  transition: min-height 0s linear 0.25s, opacity 0.25s ease-in 0.25s;
}

.stage-panel-content.active .active-content .panel-title {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.stage-panel-content.in-progress .in-progress-content .panel-title .panel-description {
  font-size: 12px;
  letter-spacing: 0.06px;
}

.stage-panel-content.in-progress
  .in-progress-content
  .panel-title
  .panel-description::first-line {
  line-height: 34px;
}

.stage-panel-content.in-progress .in-progress-content .panel-title a.panel-actions {
  color: #cccccc;
  font-size: 22px;
  padding: 27px 10px 0 0;
  text-indent: 8px;
}

.stage-panel .stage-panel-outline.in-progress,
.stage-panel .stage-panel-outline.active,
.stage-panel .stage-panel-outline.finished {
  height: 250px;
  opacity: 1;
  transition: height 0s linear, opacity 0.25s ease 0.25s;
}

.stage-panel .stage-panel-outline.in-progress,
.stage-panel .stage-panel-outline.active {
  border: 2px solid var(--primary3);
}

.stage-panel .stage-panel-outline.finished {
  border: 2px solid var(--secondary2);
}

.lock-icon {
  font-family: Material-Design-Iconic-Font, serif;
  font-size: 22px;
  color: #b2b2b2;
  font-weight: normal;
}

.lock-reason {
  font-size: 16px;
  letter-spacing: 0.4px;
  font-style: italic;
  color: #666666;
  margin-top: 0;
  margin-bottom: 0;
}

.panel-title {
  min-height: 68px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  padding: 1em 1.5em;
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 10px 10px;

  .stage-panel__tooltip {
    margin: 0;
  }
}

.panel-title span.panel-caption {
  display: inline-block;
  flex: 9;
}

.panel-caption .panel-description {
  color: #666666;
  font-size: 14px;
  letter-spacing: -0.08px;
  font-weight: normal;
}

.panel-title a.panel-actions {
  display: inline-block;
  cursor: pointer;
  min-width: 54px;
  padding: 15px 0;
  text-indent: 20px;
  font-family: Material-Design-Iconic-Font, serif;
  font-size: 20px;
  font-weight: normal;
  flex: 1;
  align-self: center;
}

.stage-panel {
  .stage-panel-status {
    display: flex;
    flex-direction: column;
  }
  &__tooltip {
    color: var(--greyscale5);
    margin: auto 0;
    opacity: 0;
    transition: opacity 0.25s ease-out 0s;
  }
  .focused &__tooltip {
    opacity: 1;
    transition: opacity 0.25s ease-in 0.25s;
  }
  & .stage-panel-overlay.inactive {
    z-index: 10;
    min-width: 420px;
    max-width: 420px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  & .stage-panel-overlay.active {
    display: none;
  }
  .stage-panel-content .stage-panel-buttons {
    padding-top: 0;
    opacity: 0;
    visibility: hidden;
    transition: height 0s linear 0.25s, padding-top 0s linear 0.25s,
      opacity 0.25s ease-out 0s, visibility 0s linear 0.25s;
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-image: none;
  }
  .stage-panel-content.inactive.focused .inactive-content.stage-panel-buttons,
  .stage-panel-content.active.focused .active-content.stage-panel-buttons,
  .stage-panel-content.in-progress.focused .in-progress-content.stage-panel-buttons,
  .stage-panel-content.finished.focused .finished-content.stage-panel-buttons {
    padding-top: 1rem;
    opacity: 1;
    transition: height 0s linear 0.25s, padding-top 0s linear 0.25s,
      opacity 0.25s ease-in 0.25s;
    visibility: visible;
  }
}
