.panel-optimization-inactive,
.panel-optimization-active {
  height: 120px;
  margin: auto;
  width: 120px;
}

.panel-optimization-inactive {
  background: url('../../../assets/images/optimize-icon_inactive.svg') no-repeat 50% 50%;
}

.panel-optimization-active {
  animation: animatedBackground 5s linear infinite;
  background: url('../../../assets/images/optimize-icon_active.svg') repeat-x 92px 50%;
  background-color: var(--greyscale1);
  border-radius: 60px;
}

#OPTIMIZE_MODEL-panel-content {
  overflow: visible;
}

@keyframes animatedBackground {
  from {
    background-position: 92px 50%;
  }
  to {
    background-position: 0 50%;
  }
}

.locked-content {
  overflow: visible;
}
