.data-viewer-tooltip {
  display: flex;
  flex-direction: column;
  border: 2px solid white;

  &__close-button {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    background-color: black;
    color: white;
    opacity: 75%;
    width: 32px;
    height: 32px;

    &:hover {
      background-color: black;
      opacity: 100%;
    }
  }

  &__metadata {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    padding: 16px 24px 24px;
  }

  &__filename {
    color: var(--greyscale7);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  &__last-edited {
    color: var(--greyscale5);
    font-size: 12px;
    line-height: 16px;
  }

  &__label {
    color: var(--greyscale7);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-top: 8px;
  }
}
