.runtime-statistic {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.25em 0;
  width: 14rem;
  background: white;

  &__title {
    font-size: 18px;
  }

  &__value {
    font-size: 18px;
    font-weight: bold;
  }
}
