.test-description {
  background-color: var(--greyscale1);
  cursor: pointer;
  padding: 0.5em;
  user-select: none;

  .MuiCardContent-root:last-child {
    padding: 0;
  }
}

.test-description__content {
  display: grid;
  grid-template-areas:
    'name expand'
    'date expand';
  grid-template-columns: auto min-content;
  grid-template-rows: min-content min-content;
}

.test-description__name {
  grid-area: name;
  font-size: 1.5rem;
  font-weight: bold;
  word-break: break-all;
}

.test-description__created-on {
  grid-area: date;
  font-size: 0.75rem;
  font-weight: 300;
}

.test-description__expand {
  grid-area: expand;
  padding: 0.5em;
}
