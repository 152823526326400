.project-button {
  min-height: 144px;
  background-color: #ffffff;
  box-shadow: var(--shadow1);
  padding: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 0.3s;
  transition-timing-function: var(--timing);
}

.project-button:hover {
  box-shadow: var(--shadow2);
}

.project-button__container {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.project-button__column {
  flex: 3 1;
  font-family: 'Proxima Nova', 'Segoe UI Light', 'Segoe UI', 'Helvetica Neue Light',
    'Helvetica Neue', Helvetica, Verdana, sans-serif;
  overflow: hidden;
  word-break: break-word;
}

.project-button__column + .project-button__column {
  margin: 0 1em;
}

.project-button__description {
  color: var(--greyscale5);
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
}

.project-button__name {
  opacity: 0.8;
  color: var(--greyscale7);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 26px;
  min-height: 26px;
}

.project-button__container > .project-status-icon {
  min-width: 100px;
  flex: 0 0;
}

.existing-project-button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}
.project-button__container > div > header {
  color: #626262;
  font-family: 'Proxima Nova', 'Segoe UI Light', 'Segoe UI', 'Helvetica Neue Light',
    'Helvetica Neue', Helvetica, Verdana, sans-serif;
  font-size: 12px;
  letter-spacing: 0.27px;
  line-height: 14px;
}

.project-button__container > div > section {
  opacity: 0.8;
  color: #3f3f3f;
  font-family: 'Proxima Nova', 'Segoe UI Light', 'Segoe UI', 'Helvetica Neue Light',
    'Helvetica Neue', Helvetica, Verdana, sans-serif;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 60px;
}

.project-button__container > div > footer {
  color: #626262;
  font-family: 'Proxima Nova', 'Segoe UI Light', 'Segoe UI', 'Helvetica Neue Light',
    'Helvetica Neue', Helvetica, Verdana, sans-serif;
  font-size: 16px;
  letter-spacing: 0.27px;
  line-height: 18px;
}

.project-button__container > .project-budget > section {
  color: #ab3c3c;
}

.project-button__container > .project-budget > footer {
  color: #ab3c3c;
}
