.annotator-stage_container {
  position: relative;
  width: 100%;
  height: 100%;
  & > .stage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.annotator-tooltip_placeholder,
.annotator-progress_placeholder {
  position: absolute;
  & > * {
    color: var(--greyscale2);
  }
}
