.detection-view {
  &__main {
    width: 100%;
    padding: 0.5em 1em 1em 1em;
    &_toolbar {
      padding: 0;
      hr {
        max-height: 20px;
        margin-top: 0.85em;
        margin-left: 0.5em;
        margin-right: 0.5em;
      }
      &_scale_dropdown {
        min-width: 70px;
      }
    }
    &_info_container > * {
      margin-right: 1em;
    }
    &_filename {
      font-weight: bold;
    }
    &_info {
      color: var(--greyscale7);
    }
  }
}
