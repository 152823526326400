.create-project-tile {
  width: 100%;
  height: 23em;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow0);
  cursor: pointer;
  transition: box-shadow 0.3s;
  transition-timing-function: var(--timing);
  &:hover {
    box-shadow: var(--shadow2);
  }

  &__icon {
    background: url('../../../assets/images/new-project-grid-card-bg.png') bottom / cover
        no-repeat,
      #ffffff;
    color: var(--white);
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      width: 28px;
      height: auto;
    }
  }

  &__description {
    padding: 1em 1.5em 2em;
    background-color: var(--white);
  }

  &__description > span {
    display: block;
    text-align: left;
    font-size: 0.75em;
    line-height: 1.33;
    color: var(--greyscale7);
  }

  &__description > p {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 600;
    color: var(--greyscale7);
    margin: 0;
  }
}
