.main-menu {
  display: flex;
  padding: 0 10px 0 0;
  margin: 0;
  align-content: stretch;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-end;
  justify-items: end;
  -webkit-justify-content: flex-end;
  height: 65px;
  background-color: var(--primary1);
  .hide-tab {
    display: none;
  }
  .text-tab,
  .icon-tab {
    margin-right: 10px;
  }
  .text-tab {
    min-width: 100px;
    max-width: 100px;
  }
  .icon-tab {
    min-width: 50px;
    max-width: 50px;
  }
  .MuiTabs-root {
    color: white;
    font-style: normal;
    text-decoration: none;
    font-weight: 700;
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    .Mui-selected {
      color: #00f6fb;
      font-weight: bold;
    }
    .MuiTabs-flexContainer {
      height: 65px;
      button span {
        font-size: 15px !important;
        letter-spacing: 1.37px !important;
      }
    }
    span.MuiTabs-indicator {
      background-color: #00f6fb;
      height: 3px;
    }
  }

  &-container {
    position: relative;
    z-index: calc(var(--drawer-z-index) + 1);
    background-color: var(--primary1);
  }

  &__sidebar {
    height: 0px;
    overflow: hidden;
    transition: height 0.1s ease-in-out;
    z-index: -1;

    &--visible {
      height: 48px;
      overflow: visible;
    }
  }
}

.menu-item {
  display: block;
  min-width: 100px;
  text-align: center;
  left: auto;
  right: auto;
  padding: 10px 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.menu-item:focus {
  outline: none;
}

.menu-item .inner-button {
  height: 48px;
  border-radius: 4px;
  font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  letter-spacing: 1.37px;
  text-decoration: none;
  text-transform: uppercase;
  color: #ffffff;
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  text-align: center;
  cursor: pointer;
}
.menu-item .inner-button:hover {
  background-color: var(--headerElementHover);
}
.menu-item .inner-button:focus {
  box-shadow: 0 0 0 2px var(--white);
  outline: none;
}
.menu-item .inner-button:active {
  background-color: var(--headerElementActive);
  box-shadow: none;
}

.menu-item:nth-child(1) {
  margin-right: auto;
  height: 48px;
  width: auto;
  margin-left: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-item:last-child .inner-button {
  height: 48px;
  width: 48px;
  min-width: 0;
  border-radius: 50%;
}
.menu-item:last-child .inner-button > div {
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.menu-item-active {
  border-bottom: var(--primary2) 3px solid;
}
.menu-item-active .inner-button {
  color: var(--primary2);
  font-weight: bold;
  cursor: pointer;
}
