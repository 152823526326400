.recent-deployments {
  &__title {
    background-color: var(--white);
    color: var(--greyscale7);
  }

  &__body-row {
    display: flex;
    justify-content: space-between;
    padding: 1em 1.5em;

    &:hover {
      background-color: transparent;
      box-shadow: var(--shadow2);
    }
  }

  &__name {
    font-size: 1rem;
    color: var(--greyscale7);
  }

  &__date {
    color: var(--greyscale7);
    font-size: 0.75rem;
    font-weight: 600;
  }
}
