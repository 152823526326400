.kebab-menu {
  &__btn {
    font-size: 1em;
    svg {
      width: auto;
      height: 1em;
    }
  }

  &__item {
    font-size: 0.875em;
    width: 15em;
  }
}
