@mixin MuiInputOverrides {
  & .MuiInput-root {
    background-color: var(--white);
    border-bottom: 1px var(--greyscale3) solid;
    margin-bottom: 1px;
    margin-top: 0.5em;
    overflow: hidden;
  }

  & .MuiInput-input {
    color: var(--greyscale7);
    padding: 1em;
  }

  & .MuiInputAdornment-positionEnd {
    position: absolute;
    right: 0;
  }

  & .MuiInputAdornment-positionStart {
    margin: 0 0.5em;
  }

  & .MuiIconButton-root {
    border-radius: 0;
    color: var(--secondary2);
    display: none;
  }

  & .Mui-focused .MuiIconButton-root,
  & .MuiIconButton-root:active,
  & .MuiIconButton-root:focus {
    display: inline-flex;
  }

  .MuiInputLabel-root {
    position: relative;
    font-size: 0.9rem;
    padding-bottom: 0.4em;
    color: var(--greyscale7);
    transform: none;
    transform-origin: unset;
  }

  & .Mui-focused {
    caret-color: var(--secondary2);
    &.MuiInputLabel-root {
      color: var(--secondary2);
    }
    &.MuiInput-root {
      background-color: var(--greyscale2);
      border-bottom: 2px var(--secondary2) solid;
      margin-bottom: 0;
    }
    & .MuiInput-input {
      color: var(--greyscale7);
    }
  }

  & .Mui-error {
    caret-color: var(--uiRed2);
    &.MuiInputLabel-root {
      color: var(--uiRed2);
    }
    &.MuiInput-root {
      background-color: var(--greyscale2);
      border-bottom: 2px var(--uiRed2) solid;
      margin-bottom: 0;
    }

    & .MuiIconButton-root {
      color: var(--uiRed2);
    }

    &.MuiFormHelperText-root {
      color: var(--uiRed2);
    }
  }

  & .Mui-focused.Mui-error {
    &.MuiInput-root .MuiInput-input {
      border-width: 2px;
    }
  }

  & .Mui-disabled {
    &.MuiInput-root,
    &.MuiInput-input {
      background-color: var(--greyscale2);
      border: none;
      color: var(--greyscale3);
    }

    &.MuiInputLabel-root {
      color: var(--greyscale3);
    }
  }

  & .MuiFormLabel-asterisk {
    white-space: nowrap;
  }
}
