.validation-card {
  height: 100%;
  padding: 20px 24px 0;
}

.validation-card h6 {
  padding: 0;
  margin: 0;
  color: var(--greyscale7);
  font-family: 'Proxima Nova';
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.validation-card-stats {
  display: flex;
  padding-bottom: 1em;
}
.validation-card-stat {
  width: 110px;
  height: 72px;
  padding: 15px 0;
  text-align: center;
  flex-flow: column nowrap;
}
.validation-card-stat .stat-num {
  height: 43px;
  width: 110px;
  font-family: 'Proxima Nova';
  font-size: 38px;
  font-weight: 600;
  line-height: 43px;
  text-align: right;
  margin-left: 20px;
  vertical-align: middle;
  min-width: 40px;
}
.validation-card-stat .stat-symbol {
  height: 26px;
  opacity: 0.4;
  color: inherit;
  font-family: 'Proxima Nova';
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.13px;
  text-align: right;
  vertical-align: top;
  top: 10px;
  margin: 10px 3px;
}
.validation-card-stat .stat-label {
  font-size: 14px;
  line-height: 3;
  font-family: 'Proxima Nova';
  font-weight: bold;
  text-align: center;
}
.validation-card-stat.float {
  color: var(--greyscale4b);
}
.validation-card-stat.comp {
  color: var(--secondary2);
}
.validation-card-stat.train {
  color: var(--primary3);
}
.validation-card-stat.other {
  height: 70px;
  width: 80px;
  border-radius: 8px;
  margin: 10px 0 0 10px;
}

.stat-num {
  box-sizing: border-box;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    border-radius: 2px;
  }
}

/* Span with bottom border */
.validation-card-stat.float .stat-num::after {
  background-color: var(--greyscale4b);
}
.validation-card-stat.comp .stat-num::after {
  background-color: var(--secondary2);
}
.validation-card-stat.train .stat-num::after {
  background-color: var(--primary3);
}

/* Graph CSS */
.validation-card-graph {
  margin-bottom: -20px;
  align-content: flex-end;
  position: relative;
}

.validation-card-graph svg {
  fill: #000;
  fill-opacity: 0.3;
}

.validation-card-graph path.float {
  stroke: var(--greyscale5);
  stroke-width: 3px;
  fill: none;
}

.validation-card-graph path.comp {
  stroke: var(--primary1);
  stroke-width: 3px;
  fill: none;
}

.validation-card-graph path.train {
  stroke: var(--primary3);
  stroke-width: 3px;
  fill: none;
}

.validation-card-graph .axis {
  stroke: var(--greyscale2);
  stroke-width: 1.5px;
}
.validation-card-graph .axis-labels text {
  fill: #000;
  fill-opacity: 0.9;
  font-size: 12px;
  font-family: 'Proxima Nova';
  font-weight: 600;
  line-height: 34px;
  text-align: center;
  text-anchor: middle;
}

/* .validation-card-graph  .axis-labels line {
    stroke: #000;
  } */

.validation-card-graph .gridline {
  opacity: 0;
}

/* Legend CSS */
.validation-legend span.hidden {
  display: none;
}

.validation-legend {
  max-width: 100px;
  max-height: 65px;
  min-width: 100px;
  /* min-height: 65px; */
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  position: absolute;
  top: 35px;
  right: 10px;
  z-index: 10;
  color: black;
  font-family: 'Proxima Nova';
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  padding-top: 10px;
}
.validation-legend > span.legend-label {
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  vertical-align: middle;
}
.validation-legend > span.legend-span {
  content: '';
  width: 35px;
  height: 4px;
  border-radius: 20px/10px;
  background: var(--greyscale5);
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
}
.validation-legend > span.legend-span.comp {
  background: var(--primary1);
}
.validation-legend > span.legend-span.retrain {
  background: var(--primary3);
}
