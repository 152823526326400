.studio-search {
  & .MuiInput-root {
    background-color: transparent;
  }

  & .Mui-focused {
    &.MuiInput-root {
      background-color: transparent;
    }
  }
}
