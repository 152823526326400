.chat-message {
  $chat-message-margin: 1.875em;
  $ca-light-color: #38447c;
  $ca-dark-color: #1e285b;
  $block: &;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: $chat-message-margin;

  &__body {
    color: var(--black);
    background-color: var(--white);
    border: 1px solid var(--greyscale2);
    box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.1);
    margin: 0.5em 0.75em 1.5em;
    padding: 1.5em 1em;
    border-radius: 0.7em;
    position: relative;
    max-width: 100%;
    overflow-wrap: break-word;
  }

  &__tail-icon {
    position: absolute;
    fill: var(--white);
    right: -6px;
    bottom: -7px;
    transform: scaleX(-1);
  }

  &--ca {
    align-items: flex-start;
    margin-left: 0;
    margin-right: $chat-message-margin;

    #{$block}__body {
      color: var(--white);
      background-color: $ca-light-color;
      border: none;
    }

    #{$block}__tail-icon {
      fill: $ca-light-color;
      left: -6px;
      right: auto;
      bottom: -7px;
      transform: none;
    }

    #{$block}__button--selected {
      color: $ca-light-color;
    }
  }

  &--dark#{&}--ca {
    #{$block}__body {
      color: var(--white);
      background-color: $ca-dark-color;
    }

    #{$block}__tail-icon {
      fill: $ca-dark-color;
    }

    #{$block}__button--selected {
      color: $ca-dark-color;
    }
  }

  & p {
    margin: 0;
  }

  &__feedback {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 24px;
    font-size: 0.75rem;

    & span {
      opacity: 0.75;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__button {
    padding: 7px;
    color: var(--white);
    background-color: rgba(256, 256, 256, 0.25);

    &:hover {
      background-color: rgba(256, 256, 256, 0.75);
    }

    &--selected,
    &--selected:disabled,
    &--selected:hover {
      background-color: var(--white);
    }

    & + & {
      margin-left: 6px;
    }

    svg {
      height: 12px;
      width: 12px;
    }
  }
}
