.data-review {
  h4 {
    text-align: center;
    font-weight: 600;
    margin: 0;
  }

  &__content {
    flex-grow: 1;
    padding: 0;
  }

  &__menu {
    display: flex;
    align-items: flex-end;
    margin-bottom: 2em;

    & > *:not(:last-child) {
      margin-right: 1em;
    }

    & > *:first-child {
      margin-right: auto;
    }
  }

  &__sub-menu {
    > * {
      margin-right: 5px;
    }
  }
}
