#model-viewer-container {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url('../../assets/images/ruler.png');
  iframe {
    width: 100%;
    height: 100%;
    z-index: -2;
    position: absolute;
    left: 0;
    top: 48px;
    border: none;
  }
}

#exit-model-viewer {
  z-index: 1;
  position: absolute;
  left: 16px;
  top: 12px;
  height: 56px;
  width: 200px;
  font-size: 16px;
}

.modal-window {
  top: 0;
  left: 0;

  &__return-btn {
    margin-top: 3em;
  }
}
