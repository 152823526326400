.prod-deploy-details {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
    max-width: fit-content;

    .studio-select .MuiInput-root {
      margin-top: 0;
    }
  }

  &__menu {
    flex: 0 0 auto;
    padding: 32px;

    & > button {
      font-size: inherit;
      padding: 10px 16px;
      margin-right: 24px;
    }
  }

  &__body {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    gap: 16px;
  }

  &__info {
    flex: 1 0 400px;
    height: 100%;
    overflow-y: auto;
    padding: 16px 32px;
  }

  &__back {
    background-color: var(--white);
    color: var(--greyscale7);
    border: 1px solid var(--greyscale3);
    box-shadow: var(--shadow2);
  }

  &__results {
    flex: 2 1 auto;
    padding: 16px 32px;
    height: 100%;
    overflow-y: auto;

    & h4 {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
    }
  }
  // delete when we have actual results to put
  #inference-results-placeholder {
    width: 850px;
    min-height: 600px;
  }

  &__header {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 16px;
    margin-bottom: 32px;
    min-width: 25%;

    & h3 {
      font-weight: 500;
      font-size: 1.75rem;
      flex: 1;
      margin: 0;
      margin-right: auto;
      word-wrap: break-word;
      max-width: 50%;
    }
  }

  &__label {
    margin-right: 4px;

    &::after {
      content: ':';
    }
  }

  &__status {
    & > * + * {
      margin-top: 5px;
    }
  }

  &__time {
    color: var(--greyscale4);
  }

  &__retrain {
    margin-left: -8px;
  }

  &__chart {
    & th {
      font-weight: normal;
      text-align: left;
    }

    & td {
      width: 100%;
    }
  }

  &__video {
    video {
      width: 100%;
    }
    margin-bottom: 16px;
  }

  &__tooltip {
    transform: translateY(25%);
  }
}
