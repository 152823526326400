.delete-integration-dialog {
  & .MuiPaper-root {
    background-color: var(--greyscale1);
  }

  &__icon {
    color: var(--uiRed2);
    position: absolute;
    left: 2rem;
  }

  &__content {
    color: var(--greyscale5);
    padding-left: 0;
    margin: 0 0 3rem;
  }

  &__title {
    font-weight: 600;
    color: var(--greyscale7);
    margin: 3rem 0 1rem;
  }

  &__title,
  &__content {
    margin-left: 5rem;
  }

  &__actions {
    background-color: var(--white);
    padding: 1rem;
  }
}
