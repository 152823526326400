@import '../../../styles/mixins.scss';

$image-size: 93px;
$image-space: 6px;

.isp-result {
  &__content {
    padding: 1em 2em;
  }
  &__title,
  &__page {
    margin: 1em 0.25em;
  }
  &__title {
    color: var(--greyscale6);
  }
  &__page {
    color: var(--greyscale7);
  }
  &__image {
    width: $image-size;
    height: $image-size;
    margin: $image-space;
  }
  &__pagination_container {
    @include center-align;
  }
  &__pagination {
    margin: 1em 0;
  }
}
