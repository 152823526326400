.sidebar {
  background-color: #080b1a;
  height: 48px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
}

.sidebar > .subheader-right-buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.sidebar > .subheader-right-buttons > button {
  margin: 0 2px;
}
