.data-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin: 0;
    font-weight: 600;
  }

  &__drop {
    width: 80%;
    margin-top: 1.5em;
  }
  &__header {
    width: 100%;
    text-align: center;
  }

  &__step-title {
    font-weight: 600;
    margin: 5em 0 0;
  }

  &__caption {
    font-weight: 100;
    color: black;
  }
}
