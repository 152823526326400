@import '../StudioTextField/shared';

.studio-select {
  @include MuiInputOverrides;

  .MuiSelect-select.Mui-disabled > span {
    cursor: default;
    color: var(--greyscale3) !important;
  }

  &__item--selected,
  &__item--selected &__selected-icon {
    color: var(--secondary2);
  }

  &__item--plain-selected {
    &.Mui-selected {
      background-color: var(--white);
    }
  }

  &__group-header {
    background-color: #ededed;
    font-style: italic;
    font-weight: 600;
  }

  & .MuiSelect-root,
  &__item {
    font: 16px 'Proxima Nova';
    padding: 1em;
    max-width: 100%;
  }

  & .MuiSelect-root {
    padding-right: 2.5em;
  }

  & .MuiSelect-root &__selected-icon,
  & .MuiSelect-root &__end-icon {
    display: none;
  }

  &__text {
    text-overflow: ellipsis;
    margin-right: 1em;
  }

  &__selected-icon {
    display: flex;
    min-width: initial;
    flex: 1;
  }

  &__auxiliary-label {
    color: var(--greyscale7);
    font-style: italic;
    font-size: 0.85rem;
    margin-right: 12px;
  }

  &__end-icon {
    display: flex;
    right: 1em;
    flex: 1;
    justify-content: flex-end;
  }

  & .MuiSelect-icon {
    margin-left: auto;
    margin-right: 1.5em;
  }

  &__menu {
    z-index: 14000 !important;
    & .MuiMenu-list {
      padding: 0;
    }

    & .MuiPaper-root {
      max-height: 14em;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .MuiInput-root {
      flex: 1;
    }

    & > *:nth-child(n + 2) {
      margin-left: 0.5em;
    }
  }

  &__tooltip {
    cursor: pointer;
  }

  &__tooltip--hidden {
    visibility: hidden;
  }

  &__chips-container {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  &__menu-footer {
    text-align: right;
    padding: 8px 16px;
    pointer-events: none;

    button {
      pointer-events: auto;
    }
  }
}
