.studio-wizard {
  .MuiDialog-paper {
    width: 470px;
    height: 500px;
    margin: 0;
  }

  .MuiDialogContent-root {
    padding: 0;
  }

  .MuiDialogContent-root {
    padding: 8px 16px;
  }

  .MuiDialogContentText-root {
    margin: 0;
  }

  .MuiIconButton-root {
    border-radius: 0;
    padding: 0;
  }

  .studio-wizard__close {
    position: absolute;
    right: 0;
    padding: 8px 16px;
  }

  .studio-wizard__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
  }

  .studio-wizard__navigation {
    position: absolute;
    left: 24px;
    bottom: 8px;
    right: 24px;
    display: flex;
    justify-content: space-between;
  }

  .studio-wizard__numbering {
    position: absolute;
    bottom: 8px;
  }

  .studio-wizard__text {
    padding: 8px 0;
    white-space: pre-line;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
    font-size: 16px;
    line-height: 24px;
  }

  .studio-wizard__title {
    font-weight: 600;
  }

  .studio-wizard__image {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 300px;
  }

  .studio-wizard__external-link {
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiSvgIcon-root {
      font-size: 1rem;
    }
  }
}
