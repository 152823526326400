.marketplace-publish {
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__loading {
    height: 120px;
    padding: 20px;
  }
}
