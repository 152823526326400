.copyable-url {
  display: flex;
  align-items: center;

  a {
    display: inline-block;
    color: var(--primary1);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 0.25em;
  }
}
