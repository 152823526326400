.metadata-list {
  & dt {
    margin-bottom: 4px;
    color: var(--greyscale4);
  }

  & dd {
    margin: 0 0 24px;
  }

  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  & li:not(:last-child) {
    margin-bottom: 4px;
  }
}
