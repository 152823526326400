.panel-finetune-inactive {
  background: url('../../../assets/images/validate-icon_inactive.svg') no-repeat 50% 50%;
  flex: 1;
}

.finetune-panel.in-progress-content {
  text-align: left;
}

#FINETUNE_MODEL-panel-content {
  overflow: visible;
}

.locked-content {
  overflow: visible;
}

.finetune-panel {
  & .MuiAlert-root {
    padding-top: 0;
    background-color: transparent;
    font-size: 0.75rem;
    align-items: center;
  }
}
