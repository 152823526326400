.marketplace-grid-item {
  $grid-background: #eee;
  $base: &;

  background-color: $grid-background;
  padding: 12px 16px;
  position: relative;
  cursor: pointer;
  position: relative;

  &--selected {
    border: 2px solid var(--primary1);
  }

  &--small {
    font-size: 0.75rem;
  }

  &--medium {
    font-size: 0.9rem;
  }

  &--light {
    color: var(--greyscale4);
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--truncated {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__radio-container {
    position: absolute;
    top: -15px;
    right: -15px;
    background-color: white;
    border-radius: 50%;
  }

  &__radio {
    padding: 0;
    & .MuiSvgIcon-root {
      font-size: 2.25rem;
    }
  }

  &__header {
    margin-top: 0.5em;
    color: var(--greyscale7);
    line-height: 0.3;
  }

  &__title {
    margin: 0;
    font-weight: 700;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-top: 12px;
  }

  &__field {
    overflow: hidden;
  }

  &__resource-info {
    justify-content: flex-start;
  }

  &__resource-info &__value {
    font-weight: 600;
  }

  &__banner {
    height: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__toolbar {
    cursor: default;
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(137, 137, 137);
    background: linear-gradient(
      180deg,
      rgba(137, 137, 137, 1) 0%,
      rgba(98, 98, 98, 1) 50%,
      rgba(45, 45, 45, 1) 100%
    );
    opacity: 0.8;
    text-align: right;
  }

  &__view-details {
    color: var(--white);
  }

  &:hover {
    #{$base}__toolbar {
      display: none;
    }
  }
}

.marketplace-tag-list {
  $font-small: 0.6rem;
  $base: &;

  & > * {
    margin-right: 0.35em;
    margin-bottom: 0.35em;
  }

  &--small {
    #{$base}__tag {
      max-width: 6em;
      height: 2em;
      font-size: $font-small;
    }

    & > * {
      margin-bottom: 0;
    }
  }

  &__tag {
    background-color: #d8d8d8;
    text-overflow: ellipsis;
  }

  &__more-tags {
    color: var(--primary1);
    font-size: 0.8rem;
    border: 1px solid var(--primary1);
    border-radius: 50%;
    display: inline-block;
    height: 1.75em;
    width: 1.75em;
    line-height: 1.75em;
    text-align: center;
  }
}
