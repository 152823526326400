.marketplace-list {
  &__table {
    border-collapse: collapse;

    & .MuiTableCell-stickyHeader {
      background-color: var(--white);
    }
  }
}

.marketplace-list__not-configured {
  display: flex;
  justify-content: center;
}

.marketplace-list__no-results {
  display: flex;
  justify-content: center;
}
