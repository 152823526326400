.mp-module {
  &__items {
    margin: 0;
    padding: 0;
    list-style: none;
    min-height: 14em;
  }

  &__item {
    display: flex;
    padding: 1em 1.5em;
    transition: box-shadow 0.3s;
    transition-timing-function: var(--timing);
    &:hover {
      box-shadow: var(--shadow2);
      cursor: pointer;
    }
  }

  &__not-configured {
    height: 14em;
    position: relative;
    & > h5 {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }
  }

  &__empty {
    height: 14em;
    position: relative;
    & > h5 {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }
  }

  &__item-thumbnail {
    height: 5em;
    width: 5em;
    margin: 0 2em 0.5em 0;
  }

  &__item-thumbnail-placeholder {
    height: 5em;
    width: 5em;
    margin: 0 2em 0.5em 0;
    background: var(--tertiaryActive);
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item-body {
    min-width: 0;
    flex: 1;
  }

  &__item-title {
    font-weight: 600;
    color: var(--primary1);
  }

  &__item-description {
    color: var(--greyscale5);
    margin: 1em 0 0;
    font-size: 0.8rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__icon {
    display: inline;
    margin-left: 1em;
    vertical-align: middle;
  }
}
