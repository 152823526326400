@font-face {
  font-family: 'ITC Avant Garde Gothic Std';
  font-style: normal;
  font-weight: 400;
  src: local('ITC Avant Garde Gothic Std Book'),
    url('../fonts/itc-avant-garde-gothic-std-book-58957161d80eb.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Std Book'),
    url('../fonts/itc-avant-garde-gothic-std-book-58957161d80eb.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std';
  font-style: normal;
  font-weight: 100;
  src: local('ITC Avant Garde Gothic Std'),
    url('../fonts/itc-avant-garde-gothic-std-extra-light-5895708744eb6.woff')
      format('woff');
  font-display: block;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std';
  font-style: normal;
  font-weight: 600;
  src: local('ITC Avant Garde Gothic Std Demi'),
    url('../fonts/itc-avant-garde-gothic-std-demi-589572a199962.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std';
  font-style: normal;
  font-weight: bold;
  src: local('ITC Avant Garde Gothic Std Bold'),
    url('../fonts/itc-avant-garde-gothic-std-bold-589572c7e9955.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std';
  font-style: normal;
  font-weight: 800;
  src: local('ITC Avant Garde Gothic Std Bold'),
    url('../fonts/itc-avant-garde-gothic-std-bold-589572c7e9955.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Material-Design-Iconic-Font';
  src: url('../fonts/Material-Design-Iconic-Font.woff2?v=2.2.0') format('woff2'),
    url('../fonts/Material-Design-Iconic-Font.woff?v=2.2.0') format('woff'),
    url('../fonts/Material-Design-Iconic-Font.ttf?v=2.2.0') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: normal;
  src: local('Proxima Nova Regular'), url('ProximaNova-Regular.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: local('Proxima Nova Regular'), url('ProximaNova-Regular.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 100;
  src: local('Proxima Nova Thin'), url('ProximaNovaT-Thin.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 200;
  src: local('Proxima Nova Light'), url('ProximaNova-Light.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  src: local('Proxima Nova Semibold'), url('ProximaNova-Semibold.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: bold;
  src: local('Proxima Nova Bold'), url('ProximaNova-Bold.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  src: local('Proxima Nova Bold'), url('ProximaNova-Bold.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 800;
  src: local('Proxima Nova Extrabold'), url('ProximaNova-Extrabld.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 900;
  src: local('Proxima Nova Black'), url('ProximaNova-Black.woff') format('woff');
  font-display: block;
}

/* @font-face {
  font-family: 'Proxima Nova Condensed';
  font-stretch: condensed;
  font-style: normal;
  font-weight: normal;
  src: local('Proxima Nova Condensed'), url('ProximaNova-Condensed.woff') format('woff');
} */
