@import './vars.scss';

.data-prepare {
  padding: 0 1em 1em 1em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: var(--greyscale6);
  font-family: 'Proxima Nova';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 00;

  &__header {
    margin: 1em 0 $layout-spacer 0;
    padding: 0.5em;
    font-weight: 600;
    & > h4 {
      text-align: center;
      font-weight: 600;
      margin: 0;
    }
  }

  &__navigation {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    & > * {
      margin-left: 1em;
    }

    & button {
      font-size: 1rem;
    }
  }

  &__loading {
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  &__previous-btn {
    background-color: var(--white);
    color: var(--greyscale7);
    border: 1px solid var(--greyscale3);
    box-shadow: var(--shadow2);
    font-size: 1rem;
  }
}

.upload-dialog {
  & .MuiDialog-paper {
    box-sizing: border-box;
    min-width: 25em;
    width: 90vw;
    background-color: var(--greyscale1);
    padding: 3em 3em 0 3em;
  }
}
