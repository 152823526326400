.test-comparison {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 1em;
}

.test-comparison__back-to-test {
  margin: 1em 0 1em auto;
  padding: 0.5em;
  width: 200px;
}

.test-comparison__view {
  background-color: var(--greyscale1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1em;
}

.test-comparison__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 1em 0 2em;
}

.test-comparison__title {
  font-size: 22px;
  font-weight: bold;
}

.test-comparison__navigation {
  align-items: center;
  display: flex;
}

.test-comparison__left,
.test-comparison__right {
  background-color: white;
  border: 1px solid var(--greyscale2);
  margin: 0 0.25em;
  padding: 6px;
}

.test-comparison__results {
  overflow-y: auto;
}
