.model-action-button {
  height: 96px;
  width: 88px;
  background: var(--white);
  padding: 0;
  border: 0;
  font-family: 'Proxima Nova';
  font-size: 14px;
  letter-spacing: 0.32px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  color: var(--greyscale7);
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.model-action-button > .button-icon {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  border: 1px solid var(--greyscale3);
  margin-bottom: 8px;
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

/* POSITION */
.model-action-button:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.model-action-button:not(:first-child) {
  border-left: 1px solid var(--greyscale2);
}
.model-action-button:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.model-action-button.model-action-button--nested {
  border-radius: 0;
}

/* PSEUDO CLASS STATE */
.model-action-button:focus {
  border: 2px solid var(--secondary2);
  outline: 0;
}
.model-action-button:hover {
  color: var(--greyscale5);
  border: 1px solid var(--greyscale3);
  &:disabled {
    border: 0;
    border-left: 1px solid var(--greyscale2);
    cursor: unset;
  }
}
.model-action-button:active {
  color: var(--greyscale5);
  background: var(--tertiaryActive);
  border: 0;
}
.model-action-button:disabled {
  color: var(--greyscale3);
  & path {
    fill: var(--greyscale3);
  }
}
.model-action-button:disabled > .button-icon {
  color: var(--greyscale3);
  border-color: var(--greyscale2);
}
.model-action-button:disabled:focus {
  border: 2px solid var(--greyscale3);
}

/* REPLACE */
.model-action-button.replace {
  & path {
    fill: var(--greyscale7);
  }
  &.disabled > .button-icon {
    & path {
      fill: var(--greyscale3);
    }
  }
}

/* DELETE */
.model-action-button.terminate,
.model-action-button.delete {
  & > .button-icon {
    border: 1px solid var(--uiRed1);
    color: var(--uiRed1);
  }
  &.disabled > .button-icon {
    & path {
      fill: var(--greyscale7);
    }
  }
  &:active {
    color: var(--uiRed2);
    background: #fbeded;
    border: 0;
  }
  &:focus {
    border: 2px solid var(--uiRed1);
    outline: none;
  }
  &:hover {
    color: var(--greyscale5);
    border: 1px solid var(--uiRed1);
    &:disabled {
      border: 0;
      border-left: 1px solid var(--greyscale2);
    }
  }
  &:focus {
    border: 2px solid var(--greyscale3);
  }
  &:disabled {
    color: var(--greyscale3);
    & > .button-icon {
      color: var(--greyscale3);
      border-color: var(--greyscale2);
    }
  }
}
