.options-builder-field {
  display: flex;
  gap: 2px;

  button {
    color: var(--secondary2);
    font-weight: 600;
  }

  &__error {
    color: var(--uiRed2);
    font-size: 0.75rem;
    margin-top: 6px;
  }
}

.options-builder-dialog {
  .MuiPaper-root {
    box-sizing: border-box;
    min-width: 25em;
    width: 100%;
    max-width: 576px;
    height: 80vh;
    background-color: var(--greyscale1);
    padding: 3em;
    overflow-y: hidden;
  }

  &__title {
    border-bottom: 1px solid #dfdfe0;
    padding-bottom: 0.5em;
  }

  &__body {
    flex: 1;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }

  .MuiTableContainer-root {
    flex: 1;
    overflow-y: auto;
  }

  table {
    margin-top: 32px;
    table-layout: fixed;
    word-break: break-word;
  }

  th {
    font-weight: 600;
  }

  td {
    border-bottom: none;
    padding: 0 24px 0 16px;
  }

  &__add {
    display: flex;
    align-items: center;
    gap: 16px;

    .MuiTextField-root {
      width: 128px;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    margin: 0 -3em -3em;
    background-color: var(--white);
    & > * {
      margin-left: 1em;
    }
  }

  &__empty {
    text-align: center;
    padding: 16px;
    font-size: 0.9rem;
  }
}
