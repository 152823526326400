.create-prod-deploy {
  padding: 30px;
  width: 100%;

  &__main {
    margin-top: 40px;
    box-shadow: 0 0 7px 0 rgba(155, 155, 155, 0.5);
    padding: 30px;

    & > h3 {
      font-weight: 600;
      font-size: 1.5rem;
    }
  }

  &__menu {
    font-size: 1rem;
    display: flex;
    align-items: flex-start;
    gap: 16px;

    & button {
      font-size: inherit;
      padding: 10px 16px;
    }

    & button:last-child {
      margin-left: auto;
    }
  }

  &__submit {
    text-align: right;

    & p {
      font-size: 1rem;
      color: var(--greyscale7);
    }
  }

  &__back {
    background-color: var(--white);
    color: var(--greyscale7);
    border: 1px solid var(--greyscale3);
    box-shadow: var(--shadow2);
  }

  %field {
    display: flex;
    width: 250px;
  }

  &__field {
    @extend %field;
    margin-bottom: 24px;

    &--large {
      width: auto;
    }
  }

  &__select {
    @extend %field;

    &__placeholder {
      font: 16px 'Proxima Nova';
      padding: 1em 2.5em 1em 1em;
      color: #c8c8c8;
    }

    &__unselected {
      @extend %field;
      &.studio-select .MuiSelect-root,
      .studio-select__item {
        color: #c8c8c8;
      }
    }

    &__selected {
      @extend %field;

      &.studio-select .MuiSelect-root,
      .studio-select__item {
        color: #3f3f3f;
      }

      &__placeholder {
        font: 16px 'Proxima Nova';
        padding: 1em 2.5em 1em 1em;
        color: #c8c8c8;
      }
    }
  }

  &__environment_item {
    display: contents;
    margin-top: 16px;
  }

  &__columns {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 150px;
  }

  &__column {
    flex: 1;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;

    & .create-prod-deploy__field {
      margin-bottom: 0;
    }
  }

  &__separator {
    align-self: center;
  }

  &__label {
    position: relative;
    font-size: 0.9rem;
    padding-bottom: 0.75em;
    color: var(--greyscale7);
    transform: none;
    transform-origin: unset;
  }

  &__details {
    margin-top: -20px;
    margin-bottom: 40px;
  }

  &__reveal {
    visibility: hidden;

    &--visible {
      visibility: visible;
    }
  }

  &__status {
    text-align: right;
  }

  &__alert {
    white-space: pre-wrap;
  }
}
