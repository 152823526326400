.classification-prepare {
  display: flex;

  &__content {
    flex-grow: 1;
  }

  &__delete.form-button.secondary {
    color: red;
  }

  &__menu {
    display: flex;
    align-items: flex-end;
    margin-bottom: 2em;

    & > *:not(:last-child) {
      margin-right: 1em;
    }

    & > *:first-child {
      margin-right: auto;
    }
  }

  &__count {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 1.5em;
  }

  &__labels {
    padding: 0;
    background-color: var(--white);
    box-shadow: var(--shadow2);
    margin: 0 1em;

    li {
      width: 10rem;
    }
  }

  &__search {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__sidebar-search {
    padding: 0;

    & .MuiInput-root {
      margin-top: 0;
    }
  }

  &__submit {
    border-radius: 100%;
    height: 4rem;
    min-width: 0;
    width: 4rem;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}
