.studio-radio-group {
  width: 100%;

  & .MuiInputLabel-root {
    position: relative;
    font-size: 1.2rem;
    color: var(--greyscale7);
  }

  & .Mui-error.MuiFormHelperText-root {
    color: var(--uiRed2);
  }

  & .Mui-disabled {
    &.MuiInputLabel-root {
      color: var(--greyscale3);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__tooltip {
    transform: translateY(-6px);
  }
}
