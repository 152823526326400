.modal-window {
  position: absolute;
  background-color: rgba(230, 230, 230, 0.25);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  margin: auto;
  animation: modal-appear 0.25s ease;
  width: 100%;
  height: 100%;
}

.modal-window.hidden {
  opacity: 0;
  animation: modal-disappear 0.25s ease;
}

@keyframes modal-appear {
  0% {
    opacity: 0;
  }
}

@keyframes modal-disappear {
  0% {
    opacity: 1;
  }
}

.modal-window form {
  animation: modal-content-appear 0.25s ease;
}

.modal-window.hidden form {
  transform: scale(0.9, 0.9);
  animation: modal-content-disappear 0.25s ease;
}

@keyframes modal-content-appear {
  0% {
    transform: scale(0.9, 0.9);
  }
}

@keyframes modal-content-disappear {
  0% {
    transform: scale(1, 1);
  }
}
