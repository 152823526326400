.file-viewer {
  flex: 1;
  &__pagination {
    margin: 1em 0;
  }

  &__pagination-container {
    display: flex;
    justify-content: center;
  }

  &__empty {
    text-align: center;
  }
}

.image-adornment {
  &__base {
    position: absolute;
    top: -0.25em;
    right: -0.25em;
    color: var(--white);
    border-radius: 50%;
    font-size: 1.2rem;
    padding: 0.1em;
  }
  &__selected {
    background-color: var(--primary3);
  }
  &__isLocal {
    background-color: var(--secondary2);
  }

  &__label {
    position: absolute;
    bottom: 0;
    left: 0;
    color: var(--black);
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    background-color: #ffef94;
    padding: 0 5px;
    width: 100%;
  }
}
