.test-region-selection {
  &-content {
    height: 100%;
    display: block !important;
  }
  &__toolbar {
    gap: 1em;
    padding: 0;
  }
  &__container {
    position: absolute;
    width: 800px;
    height: 480px;
    z-index: 9999;
    & section {
      position: relative;
      & .annotator-stage_container {
        width: 800px;
        height: 480px;
        position: absolute;
      }
    }
    & video {
      object-fit: fill;
    }
  }
}

.toolbar_spacer {
  flex-grow: 1;
}
