.project-grid-card__container {
  position: relative;
}
.project-grid-card {
  width: 328px;
  margin: 8px;
  flex-flow: column nowrap;
  box-shadow: var(--shadow0);
  cursor: pointer;
  transition: box-shadow 0.3s;
  transition-timing-function: var(--timing);
  overflow: hidden;
}
.project-grid-card__footer {
  display: flex;
  overflow: hidden;
}
.project-grid-card:hover {
  box-shadow: var(--shadow2);
}

.project-grid-card__column {
  padding: 4px 10px;
  &.model-data-col {
    max-width: 125px;
  }
}
.project-grid-card__column--name {
  flex-basis: 45%;
}
.project-grid-card__name {
  font-size: 18px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.41px;
  margin: 1em 0 0;
  opacity: 0.8;
  word-break: break-word;
}
.project-grid-card__attribute-group {
  margin: 1em 0;
}
.project-grid-card__attribute {
  font-size: 12px;
  letter-spacing: 0.27px;
  line-height: 12px;
  margin: 6px 0;
  min-height: 12px;
}
.project-grid-card__attribute--last-edited {
  font-weight: 600;
  opacity: 0.6;
  word-break: break-word;
}
.project-grid-card__attribute--header {
  opacity: 0.8;
}
.project-grid-card__attribute--value {
  font-weight: bold;
  word-break: break-word;
}
.project-grid-card__menu {
  position: absolute;
  bottom: 24px;
  right: 20px;
}

.project-grid-card__body {
  display: flex;
  overflow: hidden;
  max-height: 113px;
}
