.label-chart {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .recharts-rectangle {
    cursor: pointer;

    &:hover {
      fill: var(--primary3);
    }
  }

  &__chart {
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.3333;
    margin-top: 1.5em;
  }

  &__title {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.1428;
  }
}
