.auth-page__container {
  background-color: rgba(58, 19, 95, 1);
  background-image: url(../../assets/images/login-shape.svg),
    linear-gradient(
      180deg,
      rgba(58, 19, 95, 1) 0%,
      rgba(59, 50, 141, 1) 70%,
      rgba(35, 44, 105, 1) 100%
    );
  background-repeat: no-repeat, no-repeat;
  background-position: center, center;
  background-size: cover, cover;
  font: 16px 'Proxima Nova';
  height: 100%;
  letter-spacing: 0.32px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.auth-page__banner {
  font-size: 16px;
  line-height: 1;
  margin: 1em;
}

.auth-page__error {
  min-height: auto !important;
}

.auth-page__dialog {
  background-color: var(--greyscale1);
  margin: 3em auto;
  width: 40.5em;
  display: flex;
  flex-direction: column;
  min-height: 45em;
  overflow: hidden;
}

.auth-page__header {
  border-bottom: 1px solid var(--greyscale2);
  color: var(--greyscale7);
  display: flex;
  font: 36px 'ITC Avant Garde Gothic Std', sans-serif;
  font-weight: 200;
  line-height: 1.333333;
  margin: 1.555555em 1.777777em 0;
  padding-bottom: 0.444444em;
  width: 14.444444em;
}

.auth-page__footer {
  justify-content: space-between;
}

.auth-page__form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.auth-page__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 2em 4em;
}

.auth-page__summary {
  font-weight: 600;
  line-height: 1.5;
  margin: 1.75em 0 1em;
}

.auth-page__form-control {
  display: flex;
  margin-top: 2em;

  .MuiCollapse-hidden + & {
    margin-top: 0;
  }

  .MuiCollapse-entered + & {
    margin-top: 2em;
  }
}

.auth-page__form-control .MuiInput-input {
  height: 1.5em;
  line-height: 1.5;
  padding: 0.75em 3em 0.75em 1em;
}

.auth-page__footer {
  background-color: var(--white);
  display: flex;
  padding: 1em 1.25em 1em 1em;
}

.auth-page__secondary-button {
  border: none;
  padding: 0.75em 1.03125em;
  min-width: auto;
}

.auth-page__submit-button {
  margin-left: 0.75em;
  padding: 0.75em 3.6875em;
}
