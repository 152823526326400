.label-viewer {
  display: flex;
  flex-direction: column;

  &__images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__item {
    margin: 0.5em;
  }

  &__label {
    font-weight: 600;
  }

  &__row {
    margin: 1rem 0;
  }

  &__title {
    color: var(--greyscale7);
    font-size: 20px;
    line-height: 1.6;
  }
}
