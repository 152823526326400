.studio-page {
  height: 100%;
  background-color: var(--greyscale1);
  letter-spacing: 0.02em;

  &__content {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    position: relative;
  }
}
