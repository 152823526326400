.list-viewer {
  th {
    font-weight: bold;
    padding: 1em 0;
  }

  tr {
    &:nth-child(odd) {
      background-color: var(--white);
    }
    &:nth-child(even) {
      background-color: var(--greyscale1);
    }
  }

  td {
    padding: 0;
  }

  &__filename {
    cursor: pointer;
  }
}
