.inference-app {
  padding: 0px 20px;
  height: 100%;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__back {
    background-color: var(--white);
    color: var(--greyscale7);
    border: 1px solid var(--greyscale3);
    box-shadow: var(--shadow2);
  }

  &__menu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    padding: 16px 0;

    h2 {
      font-family: 'Proxima Nova', sans-serif;
      font-size: 1.25rem;
      font-weight: bold;
      margin: 0;
    }

    & *:first-child {
      margin-right: auto;
    }

    button {
      font-size: 1rem;
      padding: 10px 16px;
    }
  }

  &__body {
    display: flex;
    flex: 1;
    gap: 20px;
    overflow-y: hidden;
  }

  &__panel {
    width: 360px;
    display: flex;
    flex-direction: column;
  }

  &__fields {
    overflow-y: auto;
    padding: 16px;
    background-color: var(--greyscale1);
    flex: 1;

    & > * {
      margin-bottom: 32px;
    }
  }

  &__label {
    font-size: 0.9rem;
    padding-bottom: 0.4em;
    color: var(--greyscale7);
  }

  &__name {
    background-color: var(--white);
    padding: 16px;
  }

  &__submit {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 32px 16px;

    & > button {
      width: 100%;
    }
  }

  &__results {
    flex: 1;
    display: flex;
    flex-direction: column;

    ul {
      overflow-y: visible !important;
    }
  }

  &--hidden {
    visibility: hidden;
  }

  &__empty-param {
    color: var(--greyscale5);
    margin-left: 1em;
  }

  &__accordion,
  &__accordion.Mui-expanded {
    background-color: inherit;

    .MuiAccordionSummary-content {
      font-weight: 600;
    }
  }

  &__params-container {
    margin-left: -16px;
    margin-right: -16px;
  }

  &__param {
    width: 100%;
    margin: 1em 0;
    padding: 0 1em;
  }
  // hide onnx web results until all are available
  .loading-skeleton ~ .onnx-runtime {
    visibility: hidden;
  }
}
