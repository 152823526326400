.import-dialog {
  & .MuiDialog-paper {
    box-sizing: border-box;
    min-width: 25em;
    width: 90vw;
    height: 80vh;
    background-color: var(--greyscale1);
    padding: 3em 3em 0 3em;
  }

  @media screen and (min-width: 1024px) {
    & .MuiDialog-paper {
      width: 80vw;
    }
  }

  &__title {
    border-bottom: 1px solid #dfdfe0;
    padding-bottom: 0.5em;
    margin-bottom: 0;
    margin-top: 0;
    font-family: 'Proxima Nova';
    font-size: 20px;
  }

  &__body {
    flex: 1;
    display: flex;
    overflow: hidden;
  }

  &__filter-panel {
    width: 12em;
    flex-shrink: 0;
    overflow-y: auto;
  }

  &__main {
    flex: 1;
    padding: 1em 1em 1em 3em;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__tab {
    flex-direction: row;
    font-size: 1rem;
    min-height: auto;

    & .MuiTab-wrapper {
      flex-direction: row;
    }

    & .MuiSvgIcon-root {
      margin-right: 0.25em;
    }
  }

  &__tab-name {
    margin-right: 6px;
  }

  &__tab-panel {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__table {
    border-collapse: collapse;

    & .MuiTableCell-stickyHeader {
      background-color: var(--white);
    }
  }

  &__pagination {
    margin: 1em auto 0;
  }

  &__drop {
    margin-top: 1.5em;
  }

  &__files {
    max-height: none;
    min-height: 8em;
    overflow-y: scroll;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    margin: 0 -3em;
    background-color: var(--white);
    & > * {
      margin-left: 1em;
    }
  }

  &__loader {
    text-align: center;
    padding: 1em;
  }

  &__menu {
    min-height: 2.5em;
    margin: 1em 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__filter-chips {
    & > * {
      margin: 0.5em 0.5em 0 0;
    }
  }

  &__no-results {
    margin: 2em auto;
  }
}
