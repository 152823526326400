.file-explorer {
  th,
  td {
    padding: 6px 3px !important;
  }
}

.file-explorer__table {
  table-layout: fixed;
  border-collapse: collapse;
}

.file-explorer__file-icon {
  width: 32px;
  user-select: none;
}

.file-explorer__file-name {
  user-select: none;
}

.file-explorer__file-size {
  width: 64px;
  user-select: none;
}

.file-explorer__delete-icon {
  width: 32px;
  user-select: none;
}
