.flow-graph__help {
  position: fixed;
  top: 140px;
  bottom: 20px;
  right: 20px;
  width: 300px;
  z-index: 1;
  
  .flow-graph__help_content {
    padding: 1.5em;
    img {
      width: 85%;
    }
    h4 {
      margin: .25em 0;
    }
    h6 {
      margin-bottom: 0;
      margin-left: 1.5em;
    }
    p, ul, ol {
      color: var(--greyscale5);
      font-size: 14px;
      letter-spacing: 0.32px;
      line-height: 22px;
      padding: 0 1.5em;
    }
    ul, ol, code table{
      margin: .5em 1.5em;
    }
    code {
      word-break: break-all;
    }
    &_detail {
      overflow-y: scroll;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 0;
      &.line2x {
        top: 100px;
        
      }
    }
    figure {
      margin: 0;
      text-align: center;
      img {
        box-shadow: var(--shadow2);
      }
    }
    figcaption {
      font-style: italic;
      font-size: 13px;
      margin-bottom: 2em;
    }
  }
  table {
    border: 1px solid #000000;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    margin: .25em 0;
  }
  table td, table th {
    border: 1px solid #000000;
    padding: 5px 4px;
  }
  table tbody td {
    font-size: 13px;
  }
  table thead {
    background: #CFCFCF;
    background: -moz-linear-gradient(top, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%);
    background: -webkit-linear-gradient(top, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%);
    background: linear-gradient(to bottom, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%);
    border-bottom: 1px solid #000000;
  }
  table thead th {
    font-size: 15px;
    font-weight: bold;
    color: #000000;
    text-align: left;
  }
  table tfoot {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    border-top: 1px solid #000000;
  }
  table tfoot td {
    font-size: 14px;
  }

  img.scale_small {
    width: 130px;
  }
}