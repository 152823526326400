.droparea-container {
  background: white;
  outline: none;
  cursor: pointer;
  text-align: center;
  & > .droparea-inner-container {
    border-radius: 10px;
    border-width: 3px;
    border-color: #eeeeee;
    border-style: dashed;
  }
  .hit-area {
    outline: none;
  }

  &--disabled {
    cursor: auto;
  }
}
