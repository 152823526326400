@import './fonts/font-faces.css';
@import './mui-override.scss';

// Note: Temp location for app variable.
// Will revisit once we know where to put it.
$header-height: 68px;

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Proxima Nova', 'Segoe UI Light', 'Segoe UI', 'Helvetica Neue Light',
    'Helvetica Neue', Helvetica, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea {
  font-family: 'Proxima Nova', 'Segoe UI Light', 'Segoe UI', 'Helvetica Neue Light',
    'Helvetica Neue', Helvetica, Verdana, sans-serif;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.main-container {
  height: 100%;
  width: 100%;
}

/*noinspection CssNoGenericFontName*/
.material-font-icon {
  font-family: 'Material-Design-Iconic-Font';
  margin-left: 28px;
}

:root {
  /* Brand Colors */
  --primary1: #59198c;
  --primary2: #00f6fb;
  --primary3: #00aeef;
  --secondary1: #212d66;
  --secondary2: #425acc;
  /* Greyscale Colors */
  --white: #ffffff;
  --greyscale1: #f9f9fa;
  --greyscale2: #e6e6e6;
  --greyscale3: #c8c8c8;
  --greyscale4: #9c9c9c;
  --greyscale4b: #767676;
  --greyscale5: #626262;
  --greyscale6: #4c4c4c;
  --greyscale7: #3f3f3f;
  --greyscale8: #1c1c1c;
  --greyscale8: #1c1c1c;
  --greyscale9: #121212;
  --black: #000000;
  /* UI Colors */
  --uiGreen1: #36ddca;
  --uiGreen2: #19b99a;
  --uiGreen3: #1aa389;
  --uiRed1: #dd4e4e;
  --uiRed2: #ab3c3c;
  --uiRed3: #ff6666;
  --uiYellow1: #eab012;
  /* State Colors */
  --primary1Active: #380f61;
  --primary1Hover: #7922b6;
  --uiRed2Active: #8c2525;
  /* Others Colors */
  --purple-4: #efe9f4;
  /* Gradients */
  --assistantVerticalGradient: linear-gradient(180deg, #00f6fb 0%, #59198c 100%);
  --assistantHorizontalGradient: linear-gradient(90deg, #00f6fb 0%, #59198c 100%);
  --assistantDiagonalGradient: linear-gradient(
    62.71deg,
    #00f6fb 0%,
    #59198c 90%,
    #59198c 100%
  );
  --assistantSpeechBubble: linear-gradient(180deg, #212d66 0%, #425acc 100%);
  --darkShadeGradient: linear-gradient(
    222.08deg,
    rgba(0, 0, 0, 0.23) 0%,
    rgba(0, 0, 0, 0.39) 100%
  );
  --dataVisualizationGradient1: linear-gradient(153.43deg, #00f6fb 0%, #00aeef 100%);
  --dataVisualizationGradient2: linear-gradient(153.43deg, #425acc 0%, #00aeef 100%);
  --errorGradient: linear-gradient(135deg, #dd4e4e 0%, #ab3c3c 100%);
  --greenGradient: linear-gradient(137.68deg, #36ddca 0%, #19b99a 100%);
  --successGradient: linear-gradient(136.38deg, #212d66 0%, #425acc 100%);
  /* Layers - states */
  --tertiaryActive: rgba(0, 0, 0, 0.05);
  --elementDarkHover: rgba(255, 255, 255, 0.15);
  --elementDarkActive: rgba(255, 255, 255, 0.25);
  --elementHover: rgba(0, 0, 0, 0.05);
  --elementActive: rgba(0, 0, 0, 0.1);
  --headerElementHover: rgba(255, 255, 255, 0.1);
  --headerElementActive: rgba(255, 255, 255, 0.15);
  /* Layers - shadows */
  --shadow0: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(0, 0, 0, 0.1);
  --shadow1: 0 6px 8px 0 rgba(0, 0, 0, 0.1);
  --shadow2: 0 12px 14px 0 rgba(0, 0, 0, 0.1);
  --shadow3: 0 2px 90px 0 rgba(0, 0, 0, 0.15);
  --primary1Blur: 0 2px 90px 0 rgba(89, 25, 140, 0.4);
  /* Layers - borders */
  --border: 2px solid;
  --borderNonFocus: 1px solid;
  --timing: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  /* Misc */
  --drawer-z-index: 1200;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* All Global Color classes */
.primary1 {
  background-color: var(--primary1);
}
.primary2 {
  background-color: var(--primary2);
}
.primary3 {
  background-color: var(--primary3);
}
.secondary1 {
  background-color: var(--secondary1);
}
.secondary2 {
  background-color: var(--secondary2);
}
.primary1Active {
  background-color: var(--primary1Active);
}
.primary1Hover {
  background-color: var(--primary1Hover);
}
.uiRed2Active {
  background-color: var(--uiRed2Active);
}
.greyscale1 {
  background-color: var(--greyscale1);
}
.greyscale2 {
  background-color: var(--greyscale2);
}
.greyscale3 {
  background-color: var(--greyscale3);
}
.greyscale4 {
  background-color: var(--greyscale4);
}
.greyscale5 {
  background-color: var(--greyscale5);
}
.greyscale6 {
  background-color: var(--greyscale6);
}
.greyscale7 {
  background-color: var(--greyscale7);
}
.greyscale8 {
  background-color: var(--greyscale8);
}
.greyscale9 {
  background-color: var(--greyscale9);
}
.uiGreen1 {
  background-color: var(--uiGreen1);
}
.uiGreen2 {
  background-color: var(--uiGreen2);
}
.uiRed1 {
  background-color: var(--uiRed1);
}
.uiRed2 {
  background-color: var(--uiRed2);
}
.assistantVerticalGradient {
  background: var(--assistantVerticalGradient);
}
.assistantHorizontalGradient {
  background: var(--assistantHorizontalGradient);
}
.successGradient {
  background: var(--successGradient);
}
.greenGradient {
  background: var(--green);
}
.darkShadeGradient {
  background: var(--darkShadeGradient);
}
.errorGradient {
  background: var(--errorGradient);
}

/* Global Text Styles */
.bodyB2 {
  color: var(--greyscale7);
  font-family: 'Proxima Nova';
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 20px;
}
.bodySmallShort {
  color: var(--greyscale5);
  font-family: 'Proxima Nova';
  font-size: 14px;
  letter-spacing: 0.32px;
  line-height: 20px;
}
.bodySmallLong {
  color: var(--greyscale5);
  font-family: 'Proxima Nova';
  font-size: 14px;
  letter-spacing: 0.32px;
  line-height: 22px;
}
.bodyLargeShort {
  color: var(--greyscale5);
  font-family: 'Proxima Nova';
  font-size: 16px;
  letter-spacing: 0.32px;
  line-height: 24px;
}
.bodyLargeLong {
  color: var(--greyscale5);
  font-family: 'Proxima Nova';
  font-size: 16px;
  letter-spacing: 0.32px;
  line-height: 28px;
}

h6 {
  color: var(--greyscale7);
  font-family: 'Proxima Nova';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.32px;
  line-height: 18px;
}
h5 {
  color: var(--greyscale7);
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 22px;
}
h4 {
  color: var(--greyscale7);
  font-family: 'Proxima Nova';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 32px;
}
h3 {
  color: var(--greyscale7);
  font-family: 'Proxima Nova';
  font-size: 26px;
  font-weight: 300;
  line-height: 40px;
}
h2 {
  color: var(--greyscale7);
  font-family: 'Proxima Nova';
  font-size: 36px;
  font-weight: 200;
  line-height: 48px;
}
h1 {
  color: var(--greyscale7);
  font-family: 'Proxima Nova';
  font-size: 52px;
  line-height: 80px;
}
.eyebrow1 {
  color: var(--greyscale6);
  font-family: 'Proxima Nova';
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
}
.eyebrow2 {
  color: var(--greyscale6);
  font-family: 'Proxima Nova';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.35px;
  line-height: 16px;
}
.eyebrow3 {
  color: var(--greyscale5);
  font-family: 'Proxima Nova';
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
}
.textFieldlabel {
  color: var(--greyscale7);
  font-family: 'Proxima Nova';
  font-size: 14px;
  line-height: 16px;
}
.textFieldlabel:disabled {
  color: var(--greyscale3);
}
.textFieldlabel:active {
  color: var(--secondary2);
}
.textFieldlabel:invalid {
  color: var(--uiRed1);
}
.inputText::placeholder {
  color: var(--greyscale4b);
  font-family: 'Proxima Nova';
  font-size: 16px;
  line-height: 20px;
}
.inputText:disabled {
  color: var(--greyscale3);
}
.inputText:active {
  color: var(--greyscale7);
}
.helpingText:enabled {
  color: var(--greyscale4b);
  font-family: 'Proxima Nova';
  font-size: 16px;
  line-height: 20px;
}
.helpingText:disabled {
  color: var(--greyscale3);
}
.helpingText:invalid {
  color: var(--uiRed1);
}
.discoveryQuestionBodyActive {
  color: var(--greyscale7);
  font-family: 'Proxima Nova';
  font-size: 52px;
  line-height: 80px;
}
.discoveryQuestionBodyComplete {
  color: var(--greyscale4);
  font-family: 'Proxima Nova';
  font-size: 38px;
  line-height: 40px;
}
.discoveryQuestionInput::placeholder {
  color: var(--greyscale4b);
  font-family: 'Proxima Nova';
  font-size: 38px;
  line-height: 48px;
}
.discoveryQuestionInput:active {
  color: var(--greyscale7);
}
.discoveryQuestionHelpingText {
  color: var(--greyscale4b);
  font-family: 'Proxima Nova';
  font-size: 24px;
  line-height: 48px;
  text-align: right;
}
.purple-text {
  color: var(--primary1);
}
.center-text {
  text-align: center;
}
.right-text {
  text-align: right;
}

/* Global Border styles */
.borderFocusPrimary {
  border: var(--borderFocus) var(--secondary2);
}

.borderFocusSecondary {
  border: var(--borderFocus) var(--primary1);
}

.borderFocusWhite {
  border: var(--borderFocus) var(--white);
}

.borderFocusRed {
  border: var(--borderFocus) var(--uiRed1);
}

.borderFocusRedDark {
  border: var(--borderFocus) var(--uiRed2);
}

.borderFocusDisabled {
  border: var(--borderFocus) var(--greyscale3);
}

.borderFocusDisabledDark {
  border: var(--borderFocus) var(--greyscale7);
}

.borderFocusSelectionControlsPrimary {
  border: var(--borderFocus) var(--secondary2);
}

.borderFocusSelectionControlsDisabled {
  border: var(--borderFocus) var(--greyscale3);
}

.borderNonFocusGreyscale2Center {
  outline: var(--borderNonFocus) var(--greyscale2);
  outline-offset: -1px;
}

.borderNonFocusGreyscale2Inside {
  outline: var(--borderNonFocus) var(--greyscale2);
  outline-offset: -2px;
}

.borderNonFocusGreyscale2Outside {
  border: var(--borderNonFocus) var(--greyscale2);
  outline-style: solid;
  outline-color: var(--greyscale2);
}

.borderNonFocusGreyscale3Center {
  outline: var(--borderNonFocus) var(--greyscale3);
  outline-offset: -1px;
}

.borderNonFocusGreyscale3Inside {
  outline: var(--borderNonFocus) var(--greyscale3);
  outline-offset: -2px;
}

.borderNonFocusGreyscale3Outside {
  border: var(--borderNonFocus) var(--greyscale3);
  outline-style: solid;
  outline-color: var(--greyscale3);
}

.borderNonFocusRed1Center {
  outline: var(--borderNonFocus) var(--uiRed1);
  outline-offset: -1px;
}

.borderNonFocusRed1Inside {
  outline: var(--borderNonFocus) var(--uiRed1);
  outline-offset: -2px;
}

.borderNonFocusRed1Outside {
  border: var(--borderNonFocus) var(--uiRed1);
  outline-style: solid;
  outline-color: var(--uiRed1);
}

.borderNonFocusRed2Center {
  outline: var(--borderNonFocus) var(--uiRed2);
  outline-offset: -1px;
}

.borderNonFocusRed2Inside {
  outline: var(--borderNonFocus) var(--uiRed2);
  outline-offset: -2px;
}

.borderNonFocusRed2Outside {
  border: var(--borderNonFocus) var(--uiRed2);
  outline-style: solid;
  outline-color: var(--uiRed2);
}

.studio__input-field-description {
  min-height: 80px;
  max-height: 80px;
}
