.integration-tile {
  width: 19em;

  &__media {
    width: 100%;
    height: auto;
  }

  &__action-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }

  &__content {
    flex: 1;
  }

  &__description {
    color: var(--greyscale5);
    margin-top: 1em;
  }

  &__title {
    font-size: 1rem;
    font-weight: 600;
  }

  &__cta {
    padding: 1rem;
    color: var(--primary1);
    display: flex;
    align-items: center;
  }

  &__cta-text {
    margin: 1em 1em 1em 0;
    font-size: 0.8rem;
    font-weight: 600;
  }
}
