.conversation {
  &__message {
    &-enter {
      height: 0;
      opacity: 0;
      overflow: hidden;
    }

    &-enter-active,
    &-enter-done {
      height: 100%;
      opacity: 1;
      transition-property: height, opacity;
      transition-duration: 0s, 0.5s;
      transition-timing-function: linear, ease-in;
    }
  }
}
