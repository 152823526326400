.label_method-view {
  &__header {
    text-align: center;

    &_title {
      font-weight: bolder;
      margin-bottom: 0;
    }
    &_desc {
      margin-top: 1em;
      margin-bottom: 5em;
      font-weight: 500;
    }
  }

  &__note {
    font-weight: bolder;
    margin-bottom: 0em;
    margin-left: 0.5em;
  }
  &__button {
    margin: 0.5em;
    max-width: 400px;
    height: 200px;
    &.selected {
      border-width: 4px;
      border-color: #401266;
    }
    &_action_area {
      height: 100%;
    }
    &_content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      & > * {
        text-align: center;
      }
      padding: 3em;
    }
    h4 {
      margin: 0 4px 0 0;
      font-weight: 600;
    }
    h5 {
      font-weight: 400;
    }
  }

  &__cards {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  &__form {
    margin: 0 auto;
    max-width: 768px;
  }

  &__single_card {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__alert {
    max-width: 370px;
  }
}
