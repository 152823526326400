.inference-details {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__menu {
    flex: 0 0 auto;
    padding: 32px;

    & button {
      font-size: inherit;
      padding: 10px 16px;
      margin-right: 10px;
    }
    display: flex;
    justify-content: space-between;
  }

  &__body {
    flex: 0 1;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    gap: 16px;
  }

  &__info {
    flex: 1 0 400px;
    height: 100%;
    overflow-y: auto;
    padding: 16px 32px;
  }

  &__back {
    background-color: var(--white);
    color: var(--greyscale7);
    border: 1px solid var(--greyscale3);
    box-shadow: var(--shadow2);
  }

  &__results {
    flex: 2 1 auto;
    padding: 16px 32px;
    height: 100%;
    overflow-y: auto;

    & h4 {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
    }
  }

  // delete when we have actual results to put
  #inference-results-placeholder {
    width: 850px;
    min-height: 600px;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 32px;

    & h3 {
      font-weight: 500;
      font-size: 1.75rem;
      flex: 1;
      margin: 0;
      margin-right: auto;
    }
  }

  &__status {
    & > * + * {
      margin-top: 5px;
    }
  }

  &__param {
    width: 100%;
    margin: 1em 0;
    padding: 0 1em;
  }

  &__accordion,
  &__accordion.Mui-expanded {
    background-color: inherit;

    .MuiAccordionSummary-content {
      font-weight: 600;
    }
  }

  &__empty-param {
    color: var(--greyscale5);
    margin-left: 1em;
  }
}
