.studio-tabs {
  border-bottom: 1px solid var(--greyscale3);

  & .MuiTabs-indicator {
    background-color: var(--primary1);
  }

  &--primary {
    border-bottom: none;

    & .MuiTab-root {
      background-color: #d8d8d8;
      font-size: 1.25rem;
    }

    & .MuiTab-root:not(.Mui-selected):not(:last-child) {
      border-right: 2px solid var(--greyscale3);
    }

    & .MuiTab-wrapper {
      flex-direction: row;
      & > svg {
        margin-right: 0.25em;
      }
    }

    & .MuiTab-root.Mui-selected {
      background-color: var(--primary1);
      color: white;
    }
  }

  &--secondary {
    border-bottom: none;

    & .MuiTab-root {
      text-transform: uppercase;
      color: var(--greyscale5);
      border: 1px solid var(--greyscale3);
      min-width: auto;

      &:not(:first-child) {
        border-left: none;
      }
    }

    & .MuiTab-root.Mui-selected {
      background-color: var(--greyscale2);
      font-weight: 700;
    }

    & .MuiTabs-indicator {
      display: none;
    }
  }
}
