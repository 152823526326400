.dynamic-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  &__control_0 {
    margin: 0.5em 0.75em;
  }

  &__control_1 {
    margin: 0.5em 0.75em;
  }

  &__control_max {
    margin: 0.5em 0.75em;
    padding-left: 32px;
  }

  &__title {
    margin: 2rem 0 1rem;
    text-align: center;
  }
}
