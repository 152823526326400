@import '../vars.scss';

.detection-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__title {
    font-weight: 100;
  }
  &__content {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    flex-grow: 1;
  }
  &__pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    span,
    nav {
      min-width: max-content;
    }
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__alert {
    align-items: center;
    max-width: 80%;
  }
}
