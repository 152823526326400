.add-data-panel {
  background: url('../../../assets/images/data-icon_active.svg') no-repeat 50% 30px;
}

.panel-add-data-active {
  flex: 1;
}

.add-data-panel.in-progress-content {
  background: url('../../../assets/images/data-icon_active.svg') no-repeat 50% 40px;
}

.add-data-in-progress-indicator {
  width: 130px;
  height: 6px;
  border-radius: 3px;
  background: linear-gradient(to right, #6cccde, #1a8bca 50%, #3e499e);
  margin: 40px 0 0 116px;
  animation: slide-indicator 2.1s infinite ease;
}

#ADD_DATA-panel-content {
  overflow: visible;
}

.finished-content.add-data-panel .panel-title {
  width: 360px;
}

.finished-content .thumbnail {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  top: 9px;
  margin-right: 1px;
  display: block;
  border-radius: 10px 10px 0px 0px;
}

.finished-content__viewer-link {
  height: 100%;
  width: 100%;
}

@keyframes slide-indicator {
  0% {
    margin-top: 40px;
  }
  50% {
    margin-top: 130px;
  }
  100% {
    margin-top: 40px;
  }
}
