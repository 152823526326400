.flowbuilder-form {
  div[role='dialog'] {
    min-width: 400px;
    position: absolute;
    background-color: white;
    margin: 0;
    right: 0;
    bottom: 0;
    top: 120px;
  }
  .MuiBackdrop-root {
    background-color: transparent;
    display: none;
  }
  &__details {
    flex-direction: column;
    padding: 0;
  }
  &__title {
    padding-top: 2em;
    padding-bottom: 0;
    h2 {
      font-family: 'ITC Avant Garde Gothic Std';
      font-size: 1.55em;
      color: var(--greyscale7);
    }
    hr {
      margin-top: 1em;
    }
  }
  &__content {
    background-color: transparent;
  }
  &__fields {
    padding: 0.5em 0;
    border-bottom: 1px solid #f6f6f6;
    .MuiExpansionPanelSummary-content.Mui-expanded {
      margin: 0.5em 0;
    }
    &.Mui-expanded {
      padding-bottom: 1em;
      padding-top: 0;
    }

    .MuiExpansionPanelSummary-root {
      flex-direction: row-reverse;
      padding: 0;
      .MuiTypography-root {
        color: var(--greyscale7);
        font-weight: 600;
        padding-left: 1.25em;
      }
    }
    .MuiExpansionPanelSummary-expandIcon {
      padding: 0;
    }
  }
}
