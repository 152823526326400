.test-results {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.test-results__split-inference-results {
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
  overflow: scroll;

  &--streaming {
    flex-direction: row;
    justify-content: space-between;
  }
}

.test-results__stats {
  width: 100%;
  @media (min-width: 1000px) {
    min-width: 20em;
    max-width: 30em;
  }
  background: var(--greyscale1);
  margin: 0 0.5em;
}

.test-results__visualization-sticky {
  position: sticky;
  top: 0;
  > * {
    margin: 0.5em;
  }
}

.test-results__visualization-control {
  display: flex;
  align-items: center;
}

.test-results__visualization-label {
  align-items: center;
  font-weight: bold;
  margin-right: 1em;
}

.test-results__visualization-select {
  flex-grow: 1;
}
