.stage-list {
  z-index: 1;
  overflow: visible;
  margin-top: 36px;
  height: 430px;
  display: flex;
  margin-left: 100%;
  transform: scaleX(1);
  transition: all 0.5s ease;
}
