.choose-integration-dialog {
  &__top {
    align-self: flex-end;
    margin: 1.5em 3em;
    text-align: center;
  }

  &__close-btn-label {
    margin-top: 0.5em;
  }

  &__title {
    text-align: center;
    font-family: 'ITC Avant Garde Gothic Std';
    font-weight: 200;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 4em;
  }

  &__tile {
    margin: 1em 2em;
  }
}
