.marketplace-details {
  & .MuiDialog-paper {
    box-sizing: border-box;
    min-width: 25em;
    width: 90vw;
    height: 80vh;
    background-color: var(--greyscale1);
    padding: 3em 3em 0 3em;
    color: var(--greyscale7);
  }

  @media screen and (min-width: 1024px) {
    & .MuiDialog-paper {
      width: 40vw;
    }
  }

  &__title {
    border-bottom: 1px solid #dfdfe0;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    margin-top: 0;
    font-family: 'Proxima Nova';
    font-size: 20px;
  }

  &__body {
    flex: 1;
    overflow-y: auto;
  }

  &__column {
    dl {
      flex-direction: column;
    }
  }

  &__preview {
    padding: 16px 0;
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }

  &__metadata {
    padding: 16px 64px 16px 0px;

    & > dt {
      color: var(--greyscale4);
      font-size: 0.9rem;
      margin-bottom: 0.5em;
    }

    & > dd {
      margin: 0;
      font-size: 1rem;
      word-break: break-word;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    margin: 0 -3em;
    background-color: var(--white);
    & > * {
      margin-left: 1em;
    }
  }
}

.data-split-counts {
  width: 23em;

  &__total {
    font-weight: 600;
  }
}
