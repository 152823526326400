.add-streaming-device {
  .MuiPaper-root {
    box-sizing: border-box;
    min-width: 25em;
    // width: 70vw;
    height: 80vh;
    background-color: var(--greyscale1);
  }

  // @media screen and (min-width: 1024px) {
  //   .MuiPaper-root {
  //     width: 41vw;
  //   }
  // }

  &__field {
    margin: 0.5em 0.75em;
    width: 100%;

    &__label {
      color: var(--greyscale5);
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }

  &__form-body {
    padding: 1em 4em;
    overflow-x: hidden;
    & > .MuiGrid-container {
      height: 100%;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    background-color: var(--white);
    padding: 1em;
    & button {
      margin-left: 1em;
    }
  }

  &__title {
    border-bottom: 1px solid #dfdfe0;
    padding-bottom: 0.5em;
    margin: 3rem 2.5rem 0 4rem;
  }

  &__preview {
    padding: 0 1em;
  }

  &__preview-title {
    margin: 0.2em 0;
  }

  &__preview video {
    width: 100%;
    min-width: 320px;
    min-height: 180px;
    // height: 180px;
    // width: 320px;
    // height: 180px;
    background: black;
  }
  &__preview_container {
    padding: 0 2em;
    overflow: auto;
  }
  &__video_container {
    position: relative;
  }

  &__iframe-preview iframe {
    width: 320px !important;
    height: 180px !important;
  }
}
