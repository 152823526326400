.pum-container {
  font-family: 'Proxima Nova';
  font-size: 16px;
  position: relative;
  color: var(--black);
}

.pum-container > .pum-launcher svg {
  width: auto;
}
.pum-container > .pum-launcher:focus {
  outline: none;
  box-shadow: inset 0 0 0 2px var(--secondary2);
}
.pum-container > .pum-launcher:disabled {
  background-color: var(--greyscale2);
  color: var(--greyscale3);
}
.pum-container > .pum-launcher.open {
  background-color: var(--greyscale2);
  box-shadow: none;
}

.pum-container > .pum-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 280px;
  z-index: calc(var(--drawer-z-index) + 1);
  background-color: var(--white);
  box-shadow: var(--shadow2);
}
.pum-container > .pum-list.pum-from-icon {
  top: 32px;
  left: 32px;
}
.pum-container > .pum-list.pum-from-icon.pum-align-right {
  left: unset;
  right: 32px;
}
.pum-container > .pum-list.pum-from-icon.pum-align-bottom {
  top: unset;
  bottom: 32px;
}
.pum-container > .pum-list.pum-from-text {
  top: 56px;
}
.pum-container > .pum-list.pum-from-text.pum-align-right {
  right: 0px;
}
.pum-container > .pum-list.pum-from-text.pum-align-bottom {
  top: unset;
  bottom: 56px;
}
.pum-container > .pum-list .pum-list-item {
  min-height: 48px;
  width: 100%;
  padding: 8px 16px;
  color: var(--black);
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  z-index: 10;
}
.pum-container > .pum-list .pum-list-item.specialty {
  padding-left: 56px;
}

.pum-container > .pum-list .pum-list-item > .pum-list-item-border {
  position: absolute;
  width: 100%;
  padding: 0 16px;
  top: 0;
  left: 0;
}
.pum-container > .pum-list .pum-list-item > .pum-list-item-border > div {
  width: 100%;
  border-top: 1px solid var(--greyscale2);
}
.pum-container > .pum-list .pum-list-item.selected {
  color: var(--secondary2);
  font-weight: 600;
}
.pum-container > .pum-list .pum-list-item:hover {
  background-color: var(--tertiaryActive);
}
.pum-container > .pum-list .pum-list-item:active {
  background-color: var(--elementActive);
}
.pum-container > .pum-list .pum-list-item:hover > .pum-list-item-border {
  display: none;
}
.pum-container > .pum-list .pum-list-item:hover + .pum-list-item > .pum-list-item-border {
  display: none;
}
.pum-container > .pum-list .pum-list-item.disabled {
  color: var(--greyscale3);
  cursor: default;
}
.pum-container > .pum-list .pum-list-item.disabled:hover {
  background-color: var(--white);
}
.pum-container > .pum-list .pum-list-item.disabled:hover > .pum-list-item-border {
  display: block;
}
.pum-container
  > .pum-list
  .pum-list-item.disabled:hover
  + .pum-list-item
  > .pum-list-item-border {
  display: block;
}

.pum-container .pum-list-item > .pum-icon-container {
  height: 24px;
  width: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.pum-container .pum-list-item > .pum-icon-container.specialty {
  position: absolute;
  left: 16px;
}

.pum-list-item.pum-cascade-launcher .pum-cascade-list {
  visibility: hidden;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 280px;
  position: absolute;
  left: 278px;
  z-index: 3;
  background-color: var(--white);
  box-shadow: var(--shadow2);
}
.pum-list-item.pum-cascade-launcher .pum-cascade-list.pum-align-right {
  left: unset;
  right: 278px;
}

.pum-list-item.pum-cascade-launcher:hover .pum-cascade-list {
  visibility: visible;
}
.pum-list-item.pum-cascade-launcher .pum-cascade-list:hover {
  visibility: visible;
}

.pum-list-item-name {
  margin-right: 8px;
  word-break: break-word;
}

.pum-list-helping-info {
  white-space: nowrap;
}

.pum-action-segment {
  border-top: 1px solid var(--greyscale7);
  color: var(--greyscale7);
  font-weight: 600;
}
.pum-action-segment.specialty {
  padding-left: 56px;
}
.pum-container.subheader-button-holder > .pum-list {
  top: 34px;
  width: 376px;
}
.pum-container.subheader-button-holder.edit-flow-menu > .pum-list {
  top: 40px;
  right: 0px;
  width: 260px;
}
