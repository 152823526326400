.script-upload-field {
  label {
    font-size: 0.9rem;
    color: var(--greyscale7);
    padding-bottom: 0.4em;
  }

  &__drop-area {
    margin-top: 12px;
  }

  &__error {
    color: var(--uiRed2);
    font-size: 0.75rem;
    margin-top: 6px;
  }
}

.script-editor-dialog {
  .MuiPaper-root {
    box-sizing: border-box;
    min-width: 25em;
    width: 100%;
    max-width: 1024px;
    height: 80vh;
    background-color: var(--greyscale1);
    padding: 3em;
    overflow-y: hidden;
  }
  &__title {
    padding-bottom: 0.5em;
    margin: 0;
    font-weight: 600;
  }

  &__body {
    flex: 1;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    margin: 0 -3em -3em;
    background-color: var(--white);
    & > * {
      margin-left: 1em;
    }
  }
}
