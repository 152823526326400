$image-width: 125px;
$image-height: 125px;
$image-title-height: 20px;
.image-card-horizontal {
  max-width: 325px;
  height: $image-height;
  width: $image-width;
  margin: 1em;
  position: relative;
  &_title {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &_container {
    width: $image-width;
    height: $image-height;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: $image-width;
  }
  &_content {
    position: absolute;
    left: $image-width;
    right: 0;
    height: $image-height + $image-title-height;
    overflow: hidden;
  }
  &_detail_content {
    height: $image-height - 30;
  }
  &_image {
    height: $image-height;
    padding: 0 !important;
    background-color: var(--greyscale1) !important;
  }
  &_text {
    user-select: none;
  }
}
