.split-chart__row {
  display: flex;
}

.split-chart__bar {
  min-width: 50px;
  max-width: 100px;
  margin-right: 0.5em;
  width: 50%;
}

.split-chart__distribution {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
}

.split-chart__heading {
  width: 100px;
}

.split-chart__stats {
  white-space: nowrap;
  width: 100px;
}
