.studio-tags {
  display: flex;
  justify-content: space-between;

  &__tooltip-top {
    position: relative;

    .studio-tags__tooltip {
      position: absolute;
      top: -4px;
      right: 0;
    }
  }

  .MuiAutocomplete-root {
    flex: 1;
  }

  & > *:nth-child(n + 2) {
    margin-left: 0.5em;
  }

  .MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input {
    padding: 1em;

    &:first-child {
      padding: 1em;
    }
  }
}
