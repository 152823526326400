.round-button {
  display: inline-flex;
  box-shadow: var(--shadow2);
  border-radius: 50%;
  border: 1px solid transparent;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  transition-timing-function: var(--timing);
}
.round-button > div {
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
.round-button svg {
  height: auto;
  width: 20px;
}

/* SIZES */
.round-button.small {
  height: 40px;
  width: 40px;
}
.round-button.small > div {
  height: 40px;
  width: 40px;
}
.round-button.medium {
  height: 48px;
  width: 48px;
}
.round-button.medium > div {
  height: 48px;
  width: 48px;
}
.round-button.large {
  height: 72px;
  width: 72px;
}
.round-button.large > div {
  height: 72px;
  width: 72px;
}

/* ROLES */
/* PRIMARY CLASS */
.round-button.primary {
  background: var(--primary1);
  color: var(--white);
}
.round-button.primary:hover {
  background: var(--primary1Hover);
}
.round-button.primary:focus {
  border: 1px solid var(--white);
  box-shadow: var(--shadow2), 0 0 0 2px var(--primary1);
  outline: none;
}
.round-button.primary:active {
  background: var(--primary1Active);
  border: 1px solid transparent;
  box-shadow: var(--shadow2);
}
.round-button.primary:disabled {
  background: var(--greyscale2);
  box-shadow: none;
  color: var(--greyscale3);
  cursor: default;
}
.round-button.primary:disabled:focus {
  border: 1px solid var(--white);
  box-shadow: 0 0 0 2px var(--greyscale3);
}

/* SECONDARY CLASS */
.round-button.secondary {
  background: var(--white);
  border: 1px solid var(--greyscale3);
  color: var(--greyscale7);
}
.round-button.secondary:hover {
  border: 1px solid var(--greyscale2);
  color: var(--greyscale5);
}
.round-button.secondary:focus {
  box-shadow: var(--shadow2), 0 0 0 2px var(--secondary2);
  color: var(--greyscale7);
  outline: none;
}
.round-button.secondary:active {
  background: var(--tertiaryActive);
  border: 1px solid var(--greyscale2);
  color: var(--greyscale5);
}
.round-button.secondary:disabled {
  background: var(--greyscale2);
  box-shadow: none;
  border: 1px solid var(--greyscale3);
  color: var(--greyscale3);
  cursor: default;
}
.round-button.secondary:disabled:focus {
  border: 1px solid var(--white);
  box-shadow: 0 0 0 2px var(--greyscale3);
}

/* ICON-ONLY (NAKED) LIGHT */
.round-button.naked-light {
  background-color: transparent;
  box-shadow: none;
  color: var(--greyscale7);
}
.round-button.naked-light:hover {
  background: var(--elementHover);
}
.round-button.naked-light:focus {
  box-shadow: 0 0 0 2px var(--secondary2);
  outline: none;
}
.round-button.naked-light:active {
  background: var(--elementActive);
  box-shadow: none;
}
.round-button.naked-light:disabled {
  color: var(--greyscale3);
  cursor: default;
}
.round-button.naked-light:disabled:focus {
  box-shadow: 0 0 0 2px var(--greyscale3);
}

/* ICON-ONLY (NAKED) DARK */
.round-button.naked-dark {
  background-color: transparent;
  box-shadow: none;
  color: var(--white);
}
.round-button.naked-dark:hover {
  background: var(--elementDarkHover);
}
.round-button.naked-dark:focus {
  box-shadow: 0 0 0 2px var(--white);
  outline: none;
}
.round-button.naked-dark:active {
  background: var(--elementDarkActive);
  box-shadow: none;
}
.round-button.naked-dark:disabled {
  color: var(--greyscale7);
  cursor: default;
}
.round-button.naked-dark:disabled:focus {
  box-shadow: 0 0 0 2px var(--greyscale7);
}

/* SCREEN READER ONLY */
.round-button.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
