.numeric-interval-field {
  display: flex;
  align-items: center;
  gap: 8px;

  & .MuiTextField-root {
    width: 96px;
  }

  &__error {
    color: var(--uiRed2);
    font-size: 0.75rem;
    margin-top: 6px;
  }
}
