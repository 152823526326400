.panel-badge {
  position: absolute;
  right: 25px;
  top: 25px;
  & .MuiBadge-badge {
    height: 40px !important;
    min-width: 40px;
    border-radius: 40px;
  }
}
