.text-w-icon-button {
  min-width: 80px;
  font-weight: 500;
  border: 2px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  background: transparent;
  color: var(--primary1);
  cursor: pointer;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  transition-timing-function: var(--timing);
  width: 100%;

  &__text {
    width: 100%;
  }
}

/* SIZE */
.text-w-icon-button.large {
  height: 40px;
  font-size: 20px;
  line-height: 24px;
}
.text-w-icon-button.medium {
  height: 32px;
  font-size: 14px;
  line-height: 16px;
}

/* ICON LEFT OR RIGHT */
.text-w-icon-button.icon-right .buttonIcon {
  margin-left: 20px;
  display: inline-block;
  align-items: center;
}
.text-w-icon-button.icon-left .buttonIcon {
  margin-right: 16px;
  display: inline-block;
  align-items: center;
}

.text-w-icon-button.flush {
  padding: 0.2em 0;
}

.text-w-icon-button .highlight {
  font-weight: bold;
}

/* PSEUDO CLASS STATES */
.text-w-icon-button:hover {
  background: var(--elementHover);
}

.text-w-icon-button:focus {
  border: 2px solid var(--primary1);
  outline: none;
}

.text-w-icon-button:active {
  background: var(--elementActive);
  border: 2px solid transparent;
}

.text-w-icon-button:disabled {
  color: var(--greyscale3);
  cursor: default;
}

.text-w-icon-button:disabled:focus {
  border: 2px solid var(--greyscale3);
}
