.marketplace__buttons {
  display: flex;
}

.marketplace__button {
  color: var(--primary1);
  font-weight: 600;
  width: 100%;
  padding: 1em;

  &:hover {
    background-color: transparent;
    box-shadow: var(--shadow2);
  }
}

.marketplace__icon {
  display: inline;
  margin-left: 1em;
  vertical-align: middle;
}

.marketplace__lite-tab {
  padding: 3em;
}
