.method-view {
  &__header {
    text-align: center;

    & h2 {
      margin: 0;
      font-weight: 600;
    }
    & h5 {
      margin: 0 0 2em 0;
      font-weight: 100;
    }
  }

  &__button {
    margin: 0.5em;
    width: 200px;
    height: 200px;
    &.selected {
      border-width: 4px;
      border-color: #401266;
    }
    &_action_area {
      height: 100%;
    }
    &_content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h4 {
      margin: 0 4px 0 0;
    }
    .Mui-disabled {
      background-color: var(--greyscale2);
    }
  }

  &__cards {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__form {
    margin: 0 auto;
    max-width: 768px;

    & > * {
      margin-bottom: 32px;
    }
  }
}
