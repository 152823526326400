.data-viewer-summary {
  box-shadow: var(--shadow3);
  overflow: hidden;
  padding: 2em;
  margin-bottom: 1em;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  &__button {
    align-self: center;
    background-color: white;
    flex-shrink: 0;
    margin-left: 1em;
  }

  &__chart-column {
    display: flex;
    flex-basis: 300px;
    flex-grow: 1;
    height: 180px;
    min-width: 300px;
    max-width: 500px;
  }

  &__chart {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.3333;
    overflow: scroll;
  }

  &__chart-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &__chart-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.1428;
    margin: auto 0;
    font-family: 'ITC Avant Garde Gothic Std';
    font-size: 36px;
    font-weight: 100;
    line-height: 48px;
    margin-top: 3.5rem;
  }

  &__date {
    color: var(--greyscale5);
    font-size: 12px;
    line-height: 1.333;
  }

  &__dialog-title {
    background-color: var(--greyscale1);
    position: relative;
  }

  &__full-chart {
    height: 100%;
  }

  &__name {
    color: var(--greyscale7);
    font-size: 20px;
    font-weight: 600;
    line-height: 1.6;
  }

  &__rule {
    border-bottom: 1px solid var(--greyscale2);
    margin-top: 1rem;
    margin-right: 2em;
  }

  &__stat-label {
    color: var(--greyscale5);
    font-size: 16px;
    line-height: 1.5;
    margin-top: 0.625em;
  }

  &__stat-number {
    color: var(--greyscale7);
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
  }
}
