div[aria-labelledby='delete-dialog'] {
  & .MuiDialog-paperWidthSm {
    height: 296px;
    width: 640px;
  }
  & .MuiDialogTitle-root {
    background-color: var(--greyscale1);
    padding: 60px 40px 0 40px;

    & .MuiTypography-h6 {
      color: #3f3f3f;
      font-family: 'ITC Avant Garde Gothic Std';
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: 32px;
    }

    & .delete-icon {
      width: 48px;
      color: var(--uiRed2);
      display: inline-block;
    }
  }
  & .MuiDialogActions-spacing {
    height: 80px;
    padding: 12px;
  }
  & .MuiDialogContent-root {
    height: 118px;
  }
  & button {
    height: 48px;
    width: 168px;
    margin: 0 8px;
  }
  & .MuiButton-containedSecondary {
    background-color: #ab3c3c;
  }
  & .MuiDialogContentText-root {
    padding: 10px 65px;
    height: 48px;
    width: 572.8px;
    color: #626262;
    font-family: 'Proxima Nova';
    font-size: 16px;
    letter-spacing: 0.32px;
    line-height: 24px;
  }
}
