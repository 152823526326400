.studio-image-thumb {
  &__container {
    align-self: center;
    color: var(--greyscale5);
    position: relative;
    cursor: pointer;
  }
  &__progress_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__progress {
    color: var(--greyscale2);
  }
  &__broken-image {
    color: var(--greyscale2);
    background-color: var(--greyscale1);
  }
  &__image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
