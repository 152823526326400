.test-details {
  &__chart {
    padding: 0;
  }

  &__section {
    display: grid;
    grid-auto-rows: min-content;
    grid-auto-flow: row;
  }

  &__heading {
    font-weight: bold;
    padding: 0.5em 1em 0.5em 0;
    white-space: nowrap;
  }

  &__accordion-details &__heading {
    padding-left: 1em;
  }

  &__accordion-details &__accordion-details &__heading {
    padding-left: 2em;
  }

  &__item {
    padding: 0.5em 0;
  }

  &__module-attribute {
    font-weight: bold;
    font-style: italic;
  }

  &__module-name {
    font-weight: bold;
  }

  &__module-row:not(:first-of-type) &__module-name {
    margin-top: 1em;
  }

  &__from-marketplace {
    color: var(--greyscale3);
  }

  &__accordion {
    background-color: inherit;

    &--hidden {
      display: none;
    }

    &.MuiAccordion-root:before {
      opacity: 0;
    }

    &.Mui-expanded {
      margin: 0;
    }
  }

  &__accordion-summary {
    display: inline-flex;
    font-weight: bold;
    padding-left: 0;

    &.MuiAccordionSummary-root {
      min-height: 40px;
    }

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }

  &__accordion-details &__accordion-summary {
    padding-left: 1em;
  }

  &__accordion-details {
    flex-direction: column;
    padding: 0;
  }

  &__expand {
    font-size: 1rem;
  }
}
