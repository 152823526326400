.application-details {
  padding: 32px;
  display: flex;
  gap: 25px 15%;
  flex-wrap: wrap;

  &__label {
    font-size: 0.9rem;
    color: var(--greyscale7);
    font-weight: 100;
  }

  & p {
    margin: 8px 0;
  }

  & ul {
    list-style: none;
    padding: 0;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  & li:not(:last-child) {
    &::after {
      content: ',';
    }
  }

  &__list-key {
    font-weight: bold;
    margin-right: 4px;

    &::after {
      content: ':';
    }
  }
}
