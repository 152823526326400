.side-bar-menu {
  &__button {
    &.Mui-disabled > span {
      color: var(--greyscale4);
    }
    & > span {
      color: white;
      font-weight: 600;
    }
  }
  &__create {
    font-family: 'Proxima Nova';
    font-weight: 600 !important;
  }
  &__divider {
    margin: 0 !important;
    border-top: 2px solid black;
  }
  &__container {
    &__menu {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__button {
        margin-left: 8px;
        display: flex;
        gap: 4px;
      }
    }
  }
}

.processor_select {
  width: 100%;
}
