.model-details-panel {
  background-color: rgba(255, 255, 255, 1);

  &__backdrop {
    position: fixed;
    z-index: calc(var(--drawer-z-index) - 1);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .MuiDrawer-paper {
    width: 25em;
  }

  &__name {
    h3 {
      font-family: 'Proxima Nova';
      font-size: 20px;
    }
  }

  & h3,
  h6 {
    margin: 1em 0;
  }

  &__section {
    border-top: 1px solid var(--greyscale2);
    margin-left: 2em;
  }
  &__name {
    display: flex;
    flex-direction: row;
  }
  &__download {
    max-height: 36px;
    padding: 6px;
    align-self: center;
  }
  &__parameter-list {
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 0;
    font-size: 0.875em;

    & > dt {
      flex-basis: 30%;
      font-weight: bold;
      margin: 0 0 1em 0;
    }

    & > dt::after {
      content: ':';
    }

    & > dd {
      flex-basis: 60%;
      margin: 0 0 0 1em;
    }
  }
}
