.marketplace-filter-panel {
  width: 100%;

  &__filter {
    margin-bottom: 2em;
    width: 100%;
  }

  &__filter-count {
    margin-left: 0.5em;
    background-color: var(--primary1);
    color: var(--white);
    border-radius: 50%;
    border: 1px solid var(--primary1);
    display: inline-block;
    height: 1.5em;
    width: 1.5em;
    line-height: 1.5em;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
  }

  &__filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5em;
  }

  &__title {
    display: flex;
    align-items: center;
  }
}
