.dplmt-config-panel {
  background-color: var(--greyscale1);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.hide {
    display: none;
  }

  & > h4 {
    margin: 0;
    margin-bottom: 16px;
  }

  & a:active {
    color: inherit;
  }

  .MuiInputLabel-root,
  .studio-select__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .file-explorer__table {
    overflow: hidden;
    width: 100%;
  }

  .studio-select__tooltip {
    margin-bottom: 16px;
  }
}

.dplmt-app-actions {
  justify-items: flex-end;
}

.dplmt-config-panel .dplmt-download {
  display: inline-block;
  color: var(--greyscale7);
  padding: 10px;
  border-radius: 50%;
  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.3);
  }
}

.dplmt-config-panel .dplmt-delete-app {
  display: inline-block;
  color: var(--greyscale7);
  padding: 10px;
  border-radius: 50%;
  margin-bottom: 2px;
  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.3);
  }
}

.dplmt-history-card {
  cursor: pointer;
  margin: 1em;
  min-width: 275px;
  user-select: none;
}

.dplmt-history-card-action {
  margin-top: 12px;
}

.dplmt-history-card-contents {
  font-size: 12px;
  padding-top: 0;
  padding-bottom: 0;
}

.dplmt-history-card-header {
  padding-top: 0;
  padding-bottom: 0;

  .MuiCardHeader-content {
    overflow: hidden;
    white-space: nowrap;
  }
}

.dplmt-history-card-stats {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  font-weight: 300;
}

.dplmt-tabs {
  font-weight: bold;

  & .MuiTab-root {
    background-color: #d8d8d8;
    font-size: 16px;
    opacity: 1;
  }

  & .MuiTab-root.Mui-selected {
    background-color: var(--primary1);
    color: white;
    font-weight: bold;
  }
}

.dplmt-tab-panel:not([hidden]) {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.dplmt-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.dplmt-scrollable-container {
  background-color: var(--greyscale1);
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.dplmt-config-field {
  width: 100%;
  margin: 1em 0;
  padding: 0 1em;

  & > .textFieldlabel {
    margin-bottom: 8px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    transition-timing-function: var(--timing);
  }

  & > .textFieldlabel.disabled {
    color: var(--greyscale3);
  }

  & .MuiFormGroup-root {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  & .ddm-container {
    min-width: unset;
    width: 100%;
  }

  & .ddm-launcher {
    position: relative;
  }

  &__alert {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
}
.dplmt-config-action-buttons {
  background-color: white;
  box-shadow: 0px 0px 1px 1px var(--greyscale2);
  margin: 1px;
  padding: 1em;
}

.dplmt-config__link {
  color: var(--primary1);
  font-size: 0.9rem;
  font-weight: 600;
}

.dplmt-inference-app__link {
  padding-left: 0;
}

.dplmt-history-card-checkbox {
  &,
  & > .MuiFormControlLabel-root {
    width: 100%;
  }

  .MuiFormControlLabel-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.dplmt-config__accordion,
.dplmt-config__accordion.Mui-expanded {
  background-color: inherit;

  .MuiAccordionSummary-content {
    font-weight: 600;
  }
}

.dplmt-config__accordion_reset {
  float: right;
}

.dplmt-config__empty {
  color: var(--greyscale5);
  margin-left: 1em;
}
