.marketplace-explorer {
  display: flex;
  width: 100%;

  &__filters {
    width: 20em;
    flex-shrink: 0;
    padding: 2em;
    overflow-y: auto;
  }

  &__main {
    background-color: var(--white);
    flex-grow: 1;
    padding: 2em 4em 0 2em;
    min-width: 0;

    display: flex;
    flex-direction: column;
  }

  &__main-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__main-bar-right {
    display: flex;
    align-items: flex-end;
    > * {
      margin-left: 1em;
    }
  }

  &__purpose-tabs {
    margin-top: 1em;
  }

  &__bar {
    margin-top: 0.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__delete {
    margin-left: auto;
    margin-right: 1em;
  }

  &__action {
    font-size: 1rem;
  }

  &__results {
    flex: 1;
    overflow-y: auto;
    padding-top: 1.5em;
  }

  &__not-configured {
    text-align: center;
    margin: 2em 0;
  }

  &__empty {
    text-align: center;
    margin: 2em 0;
  }

  &__pagination {
    margin: 2.5em auto;
  }
}
