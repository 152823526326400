.clickable-list-form {
  & .clickable-list-form__label {
    font-size: 1rem;
    color: var(--greyscale7);
    margin-bottom: 1em;
    display: block;
  }

  &__option {
    padding: 0.5em 0;
    text-align: left;

    &.text-w-icon-button.medium {
      height: auto;
      line-height: normal;
    }
  }
}
