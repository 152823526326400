.studio-statistics__all-stats {
  display: flex;
  justify-content: space-between;
  padding: 0 1em;
}

.studio-statistics__stat-column {
  display: inline-block;
  flex-direction: column;
}

.studio-statistics__stat-num {
  height: 43px;
  font-family: 'Proxima Nova';
  font-size: 38px;
  font-weight: 600;
  line-height: 43px;
  text-align: center;
  vertical-align: middle;
  min-width: 40px;
}

.studio-statistics__stat {
  text-align: center;
}
