.studio-radio {
  display: flex;
  flex-direction: column;

  &__main {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__tooltip {
      align-self: center;
      color: var(--greyscale5);
    }

    &__tooltip--hidden {
      visibility: hidden;
    }
  }

  &__helper {
    margin-top: -10px;
    margin-left: 35px;
    font-size: 14px;
  }

  &.MuiFormGroup-root {
    flex-direction: row;
    justify-content: space-between;
  }

  & .MuiRadio-root {
    margin: 0.125em;
  }

  & .MuiRadio-root.Mui-checked {
    color: var(--secondary2);
    font-weight: bold;
  }

  & .MuiRadio-root.Mui-focusVisible,
  & .MuiRadio-root:hover {
    background-color: #ebebec;
  }

  & .MuiRadio-root:active {
    background-color: #dfdfdf;
  }

  & .MuiFormControlLabel-label {
    color: var(--greyscale7);
    user-select: none;
  }

  & .MuiRadio-root.Mui-checked + .MuiFormControlLabel-label {
    color: var(--secondary2);
    font-weight: bold;
  }

  & .Mui-disabled {
    & .MuiRadio-root.Mui-checked + .MuiFormControlLabel-label,
    &.MuiFormControlLabel-label,
    &.MuiRadio-root {
      color: var(--greyscale3);
    }
  }
}
