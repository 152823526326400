.confirm-dialog {
  &__title-container {
    display: flex;
    align-items: center;
  }

  &__title {
    margin-left: 1.15em;
    font-size: 1.25em;
    font-weight: 600;
  }
  &__message {
    color: var(--greyscale5);
    font-family: 'Proxima Nova';
    font-size: 1.1em;
    letter-spacing: 0.5px;
    line-height: 32px;
    letter-spacing: 0.32px;
    line-height: 24px;
    padding: 10px 65px;
  }
  & .MuiDialogTitle-root {
    background-color: var(--greyscale1);
    padding: 60px 40px 0 40px;
  }
  & .MuiDialogActions-spacing {
    height: 80px;
    padding: 12px;
  }
  & .MuiDialogContent-root {
    min-height: 118px;
  }
  & button {
    height: 48px;
    min-width: 168px;
    margin: 0 8px;
  }
}
