.processing-form {
  padding: 0px 16px 16px;
  background-color: white;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0px;

    & > button {
      font-size: inherit;
      padding: 8px 16px;
    }
  }

  hr {
    margin-top: 32px;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 0;
  }

  h5 {
    margin-top: 8px;
  }

  &__main {
    background-color: var(--greyscale1);
    box-shadow: 0px 0px 4px 1px var(--greyscale2);
    padding: 24px;
    overflow-y: auto;
    flex: 1;
  }

  &__back {
    background-color: var(--white);
    color: var(--greyscale7);
    border: 1px solid var(--greyscale3);
    box-shadow: var(--shadow2);
  }

  &__form-top {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 448px));
    grid-auto-rows: min-content;
    row-gap: 32px;
    column-gap: 128px;
  }

  .MuiTableContainer-root {
    display: flex;
    flex-direction: column;
  }

  table {
    table-layout: fixed;
    width: auto;
  }

  td,
  th {
    padding: 12px;
  }

  td {
    border-bottom: none;
  }

  &__new-param {
    color: var(--secondary1);
    font-weight: 600;
    align-self: flex-start;
  }

  &__empty {
    text-align: center;
    padding: 16px;
    font-size: 0.9rem;
  }

  &__param-field {
    min-width: 160px;
  }

  label {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}
