.MuiAlert-root {
  border-radius: 0.4rem;
  &.MuiAlert-filledError {
    background: rgb(221, 78, 78);
    background: linear-gradient(
      90deg,
      rgba(221, 78, 78, 1) 0%,
      rgba(171, 60, 60, 1) 100%
    );
    h6 {
      color: white;
      margin: 4px 0;
    }
  }
  &.MuiAlert-filledInfo {
    background: rgb(33, 45, 102);
    background: linear-gradient(
      90deg,
      rgba(33, 45, 102, 1) 0%,
      rgba(66, 90, 204, 1) 100%
    );
  }
}
