.label-menu {
  &__input_container {
    padding: 0;
  }
  & input {
    // padding: 1em 0.5em 0.5em 0.5em;
    padding: 0.75em 0.25em;
  }
  & .MuiPaper-root {
    border-radius: 0.25em;
  }
}
