@mixin transition($property, $duration, $type) {
  webkit-transition: $property $duration $type;
  -moz-transition: $property $duration $type;
  -o-transition: $property $duration $type;
  transition: $property $duration $type;
}

@mixin center-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
