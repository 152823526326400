.assistant-panel {
  position: absolute;
  width: 440px;
  top: 75px;
  left: calc(50% - 220px);
}

.assistant-panel.top {
  height: 80px;
  z-index: 101;
  animation: panel-appear 0.3s ease;
}

.assistant-panel.top.hidden {
  opacity: 0;
  animation: panel-disappear 0.3s ease;
}

.assistant-panel.cap {
  z-index: 100;
  animation: panel-appear 0.3s ease;
}

.assistant-panel.cap.hidden {
  opacity: 0;
  animation: panel-disappear 0.3s ease;
}

.assistant-panel.bottom {
  z-index: 101;
  position: relative;
  top: 56px;
  animation: panel-appear 0.3s ease;
}

.assistant-panel.bottom.hidden {
  opacity: 0;
  animation: panel-disappear 0.3s ease;
}

.assistant-bullet {
  background: url('../../assets/images/assistant.svg') 50% 0 no-repeat;
  position: absolute;
  width: 80px;
  height: 80px;
  left: calc(50% - 40px);
  animation: bullet-appear 0.3s ease;
}

.assistant-panel.top.hidden .assistant-bullet {
  opacity: 0;
  transform: scale(0.5, 0.5);
  animation: bullet-disappear 0.3s ease;
}

.assistant-panel.cap .assistant-panel-filler {
  height: 29px;
  animation: cap-appear 0.3s ease;
}

.assistant-panel.cap.hidden .assistant-panel-filler {
  height: 59px;
  animation: cap-disappear 0.3s ease;
}

.assistant-panel.bottom .assistant-panel-filler {
  height: 70px;
  animation: bottom-appear 0.3s ease;
}

.assistant-panel.bottom.hidden .assistant-panel-filler {
  height: 100px;
  animation: bottom-disappear 0.3s ease;
}

.assistant-panel-header {
  background: url('../../assets/images/assistant-dlg-bg.png') 0 0 no-repeat;
  height: 56px;
  /*box-shadow: 0 12px 14px 0 rgba(0,0,0,0.1);*/
}

.assistant-panel-border {
  background: url('../../assets/images/assistant-dlg-triangle.svg') 50% 10px no-repeat;
  height: 30px;
}

.assistant-panel-content {
  border-radius: 0 0 8px 8px;
  background-color: #f9f9fa;
  margin-top: -1px;
  box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.1);
  max-height: 500px;
  overflow-y: scroll;
}

.assistant-panel-content .studio-form .form-data {
  max-height: 226px;
  padding: 23px 30px 30px 30px;
  top: -15px;
  /*display: block;*/
  width: 100%;
  position: relative;
  z-index: 4;
  overflow-y: auto;
  /*min-height: 200px;*/
}

.assistant-panel-content .studio-form .form-data #dropdown-assistant:nth-child(1) {
  z-index: 1001;
}

.assistant-panel-content .studio-form .form-data #dropdown-assistant:nth-child(2) {
  z-index: 1000;
}

.assistant-panel-content .studio-form .form-data label {
  color: #333333;
  line-height: 18px;
  font-size: 14px;
  margin: 5px 10px 0 10px;
  width: auto;
  float: left;
  position: relative;
  display: inline-block;
  font-weight: 600;
}

.assistant-panel-content .studio-form .form-data .check-box:disabled + label {
  color: #cccccc;
}

.assistant-panel-content .studio-form .form-data .ddm-container .ddm-list {
  overflow-y: scroll;
  position: relative;
  max-height: 219px;
  z-index: 7;
}

.assistant-panel-content .studio-form .form-data .ddm-container .ddm-launcher {
  z-index: 6;
}

.assistant-panel-content .studio-form .form-data .ddm-container {
  z-index: 5;
}

.assistant-panel-content .studio-form .check-box-container {
  display: inline-flex;
  position: relative;
  padding-bottom: 30px;
}

.assistant-panel-content .studio-form .form-data .check-box-container label {
  font-size: 16px;
  font-weight: 500;
  width: 300px;
}

.modal-window.hidden {
  opacity: 0;
}

.assistant-panel-content .studio-form {
  position: static;
  box-shadow: none;
  background: none;
  min-height: 370px;
  z-index: 3;
}

.assistant-panel-content .studio-form .form-header {
  border: none;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.41px;
  line-height: 28px;
  font-family: 'Proxima Nova';
  min-height: 60px;
  position: relative;
  top: -10px;
  left: -18px;
}

/*.assistant-panel-header .assistant-triangle {*/
/*    width: 0;*/
/*    height: 0;*/
/*    position: absolute;*/
/*    !* margin: auto; *!*/
/*    left: 209px;*/
/*    !* right: 19px; *!*/
/*    border-left: 10px solid transparent;*/
/*    border-right: 10px solid transparent;*/
/*    border-bottom: 10px solid var(--white);*/
/*    bottom: 0px;*/
/*}*/

.assistant-panel-content .studio-form .form-data > label {
  margin: 0 0 6px 0;
  width: 350px;
}

.assistant-panel-content .studio-form .form-data > input,
.assistant-panel-content .studio-form .form-data > select {
  margin-bottom: 16px;
  min-width: 380px;
}

.assistant-panel-content .studio-form .form-data .input-shape > input {
  margin-bottom: 16px;
}

.assistant-panel-content .studio-form .form-buttons {
  background: none;
  padding: 20px 30px 37px 30px;
  /*top: -45px;*/
  position: relative;
  z-index: 3;
}

.assistant-panel-content .form-button {
  width: 100%;
}

.info-icon {
  color: var(--greyscale4);
  width: 18px;
  display: inline;
  margin-top: -24px;
  margin-left: 362px;
}

.info-icon:hover {
  color: var(--primary2);
}

@keyframes bullet-appear {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
}

@keyframes bullet-disappear {
  0% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes cap-appear {
  0% {
    height: 59px;
  }
}

@keyframes cap-disappear {
  0% {
    height: 29px;
  }
}

@keyframes bottom-appear {
  0% {
    height: 100px;
  }
}

@keyframes bottom-disappear {
  0% {
    height: 70px;
  }
}

@keyframes panel-appear {
  0% {
    opacity: 0;
  }
}

@keyframes panel-disappear {
  0% {
    opacity: 1;
  }
}
