.dashboard {
  $border-color: #ececec;

  height: 100%;

  background-color: var(--greyscale1);
  letter-spacing: 0.02em;
  min-width: 0;

  &__header {
    font-size: 1.25rem;
    line-height: 1.2;
    font-weight: 600;
    color: var(--greyscale7);
    margin-top: 0.5em;
  }

  &__paper {
    font-size: 0.875rem;
    line-height: 1.15;
    box-shadow: var(--shadow0);
    padding: 1em 1em 0;
  }

  &__item--lite {
    padding: 1.5em 2em;
  }

  &__column {
    padding: 1em;
    border-left: 1px solid $border-color;
    height: 100%;
    overflow-y: auto;
  }

  &__column > * {
    margin-bottom: 1.5em;
  }

  &__column > &__item--lite {
    margin-bottom: 2em;
  }

  &__item-title {
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 1em 0;
  }

  &__item-body {
    margin: 0 -1em;
  }

  &__view-all {
    & a {
      color: var(--greyscale7);
    }
    font-size: 1rem;
    line-height: 1.5;
    text-align: right;
  }

  &__empty {
    text-align: center;
    padding: 24px;
  }

  @media screen and (min-width: 1200px) {
    &__column {
      padding: 2.815em;
    }
  }

  &__tour-guide-row {
    margin-left: auto;
  }

  & &__tour-guide-button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: var(--white);
    border: 1px solid var(--primary1);
    color: var(--primary1);
    width: 140px;
    padding: 0 16px;

    &:hover {
      background: var(--greyscale1);
    }

    &:focus {
      box-shadow: var(--shadow2), 0 0 0 2px var(--primary1);
      outline: none;
    }

    &:active {
      box-shadow: var(--shadow2), 0 0 0 2px var(--primary1);
    }

    &:disabled {
      background: var(--greyscale2);
      box-shadow: none;
      color: var(--greyscale3);
      cursor: default;
    }

    &:disabled:focus {
      border: 1px solid var(--white);
      box-shadow: 0 0 0 2px var(--greyscale3);
    }
  }
}
