.integrations-tab {
  &__create {
    width: 90%;
    margin: 0 auto 4em;
  }

  & .studio-form {
    top: 50%;
    transform: translate(0, -50%);
  }
}
