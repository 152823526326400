.form-button {
  min-width: 114px;
  height: 56px;
  font: 16px 'Proxima Nova', serif;
  letter-spacing: 0.32px;
  line-height: 1.5;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: var(--shadow2);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  transition-timing-function: var(--timing);
  box-shadow: none;
  user-select: none;
}

.form-button.with-margin {
  margin-left: 15px;
}

.form-button .buttonIcon {
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.form-button .left {
  margin-left: 0 !important;
  margin-right: 15px !important;
}

/* ROLES */
/* PRIMARY CLASS */
.form-button.primary {
  background: var(--primary1);
  color: var(--white);
}

.form-button.primary:hover {
  background: var(--primary1Hover);
}

.form-button.primary:focus {
  border: 1px solid var(--white);
  box-shadow: var(--shadow2), 0 0 0 2px var(--primary1);
  outline: none;
}

.form-button.primary:active {
  background: var(--primary1Active);
  border: 1px solid transparent;
  box-shadow: var(--shadow2);
}

.form-button.primary:disabled {
  background: var(--greyscale2);
  box-shadow: none;
  color: var(--greyscale3);
  cursor: default;
}

.form-button.primary:disabled:focus {
  border: 1px solid var(--white);
  box-shadow: 0 0 0 2px var(--greyscale3);
}

/* SECONDARY CLASS */
.form-button.secondary {
  background: var(--white);
  border: 1px solid var(--greyscale3);
  color: var(--greyscale7);
}

.form-button.secondary:hover {
  border: 1px solid var(--greyscale2);
  color: var(--greyscale5);
}

.form-button.secondary:focus {
  box-shadow: var(--shadow2), 0 0 0 2px var(--secondary2);
  color: var(--greyscale7);
  outline: none;
}

.form-button.secondary:active {
  background: var(--tertiaryActive);
  border: 1px solid var(--greyscale2);
  color: var(--greyscale5);
}

.form-button.secondary:disabled {
  background: var(--greyscale2);
  box-shadow: none;
  color: var(--greyscale3);
  cursor: default;
  border: none;
}

.form-button.secondary:disabled:focus {
  border: 1px solid var(--white);
  box-shadow: 0 0 0 2px var(--greyscale3);
}

/* DELETE CLASS */
.form-button.delete {
  background: var(--uiRed2);
  color: var(--white);
}

.form-button.delete:hover {
  background: var(--uiRed1);
}

.form-button.delete:focus {
  border: 1px solid var(--white);
  box-shadow: var(--shadow2), 0 0 0 2px var(--uiRed2);
  outline: none;
}

.form-button.delete:active {
  background: var(--uiRed2Active);
  border: 1px solid transparent;
  box-shadow: var(--shadow2);
}

.form-button.delete:disabled {
  color: var(--greyscale3);
  background: var(--greyscale2);
  box-shadow: none;
  cursor: default;
}

.form-button.delete:disabled:focus {
  border: 1px solid var(--white);
  box-shadow: 0 0 0 2px var(--greyscale3);
}

/* ASSISTANT CLASS */
.form-button.assistant {
  background: rgba(255, 255, 255, 0.05);
  box-shadow: none;
  color: var(--white);
}

.form-button.assistant:hover {
  background: rgba(255, 255, 255, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.form-button.assistant:focus {
  box-shadow: 0 0 0 2px var(--white);
  outline: none;
}

.form-button.assistant:active {
  background: rgba(255, 255, 255, 0.12);
  border: 1px solid transparent;
  box-shadow: none;
}

/* TEXT-ONLY (NAKED) CLASS */
.form-button.naked {
  background-color: transparent;
  box-shadow: none;
  color: var(--greyscale7);
}

.form-button.naked:hover {
  background: var(--elementHover);
}

.form-button.naked:focus {
  box-shadow: 0 0 0 2px var(--secondary2);
  outline: none;
}

.form-button.naked:active {
  background: var(--elementActive);
  box-shadow: none;
}

.form-button.naked:disabled {
  color: var(--greyscale3);
  cursor: default;
}

.form-button.naked:disabled:focus {
  box-shadow: 0 0 0 2px var(--greyscale3);
}

/* ICONS */
.form-button.with-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}
.form-button.with-icon > div {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
