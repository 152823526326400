.label-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__drop {
    margin-top: 1.5em;
    width: 80%;
    flex-grow: 1;
  }

  &__manual-btn {
    color: var(--secondary2);
  }

  &__header {
    width: 100%;
  }
  h5 {
    margin: 0.5em;
  }
}

.label-review {
  &__header {
    text-align: center;
    font-weight: 600;
    margin-bottom: 1em;
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0.5em;
  }

  &__btn-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    & > * {
      margin-left: 2em;
    }
  }

  &__count {
    font-size: 1.2rem;
    font-weight: 600;
  }

  &__editor {
    margin-left: 0;
    padding: 0;
  }

  &__group {
    background-color: var(--white);
    list-style-type: none;
    margin-bottom: 0.5em;
    padding: 1em 1em 0.5em;
  }

  &__tooltip {
    transform: translate(8px, 6px);
  }

  &__group-header {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
  }

  &__empty {
    text-align: center;
    margin-top: 2em;
  }
}

.label-list {
  font-size: 1rem;
  line-height: 2.5;
  list-style-type: none;
  padding: 0;

  & > * {
    margin-right: 2em;
  }

  &__chip {
    display: inline-flex;
    border: none;
    align-items: center;
  }

  &__delete {
    margin-left: 0.5em;
    background-color: var(--greyscale2);
    color: var(--greyscale4);
    height: 1.5em;
    width: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;

    & svg {
      height: 12px;
      width: 12px;
    }

    &:hover {
      background-color: var(--greyscale3);
      color: var(--greyscale1);
    }
  }
}

.add-label-dialog {
  .MuiPaper-root {
    box-sizing: border-box;
    min-width: 25em;
    width: 70vw;
    height: 80vh;
    background-color: var(--greyscale1);
    padding: 3em;
  }

  @media screen and (min-width: 1024px) {
    .MuiPaper-root {
      width: 41vw;
    }
  }

  &__title {
    border-bottom: 1px solid #dfdfe0;
    padding-bottom: 0.5em;
  }

  &__body {
    flex: 1;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    margin: -3em;
    background-color: var(--white);
    & > * {
      margin-left: 1em;
    }
  }

  &__submit {
    margin-top: 0.5em;
    font-style: italic;
  }

  &__input {
    flex: 1;
    & .MuiInput-root {
      background-color: transparent;
    }

    & .Mui-focused {
      &.MuiInput-root {
        background-color: transparent;
      }
    }
  }
}
