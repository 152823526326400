.download-edge-installer-dialog {
  flex-shrink: 0;
  line-height: 20px;
  letter-spacing: 0.16px;
  .MuiDialogContent-root {
    padding: 8px 32px;
  }
  .MuiDialogTitle-root {
    color: #3f3f3f;
    padding: 12px 12px 12px 32px;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    background: #f9f9fa;
  }
  .download-edge-installer-dialog-title {
    .MuiTypography-body1 {
      color: var(--greyscale7);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: 'Proxima Nova';
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 130% */
      letter-spacing: 0.16px;
    }
  }
  &_download-icon {
    margin-left: 33%;
    margin-top: 12px;
    margin-bottom: 20px;
    .MuiSvgIcon-root {
      width: 200px;
      height: 105.019px;
      flex-shrink: 0;
    }
  }
  .studio-radio.MuiFormGroup-root {
    display: flex;
    padding: 0 20px 12px 30px;
    margin-bottom: 8px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--greyscale3);
  }
  .studio-radio.MuiFormGroup-root:has(.MuiRadio-root.Mui-checked) {
    border: 1px solid var(--greyscale7);
  }
  .MuiDialogContentText-root {
    font-size: 14px;
  }
  .MuiTypography-colorTextSecondary {
    color: var(--greyscale7);
  }
  .studio-radio {
    .studio-radio__helper {
      font-size: 16px;
    }
    .MuiRadio-root.Mui-checked,
    .MuiRadio-root.Mui-checked + .MuiFormControlLabel-label {
      color: var(--greyscale7);
      font-weight: bold;
    }
  }
  .download-edge-installer-link {
    margin-top: 16px;
    margin-bottom: 12px;
    a {
      color: var(--secondary2);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: 'Proxima Nova';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.16px;
      text-decoration-line: underline;
    }
  }
  &_link-icon {
    color: var(--secondary2);
    display: inline-block;
    margin-left: 8px;
    flex-shrink: 0;
  }
  &_download-edge-installer-close-button {
    float: right;
    align-items: center;
    padding: 0;
  }
  .studio-radio__helper {
    margin-bottom: 0;
  }
}
