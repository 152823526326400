.properties-panel {
  .MuiDrawer-paper {
    width: 25em;
    padding-top: 3em;
  }

  &__backdrop {
    position: fixed;
    z-index: calc(var(--drawer-z-index) - 1);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.properties-panel-form-content {
  background: var(--white);
}

.properties-panel-form-content {
  > .content-upper {
    padding: 40px;
    width: auto;
    height: unset;
    .content-button-container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-top: 15px;

      .content-middle {
        position: relative;
        width: auto;
        margin-right: 10px;
        height: 100px;
        display: flex;
        flex-direction: row;

        .model-action-button:not(:first-child) {
          border-left: none !important;
        }
        .model-action-button:not(:first-child):hover {
          border: 1px solid var(--greyscale2) !important;
          border-radius: 0;
        }
      }
    }
    .name-size {
      float: right;
      color: var(--greyscale6);
      font-size: small;
    }
    .name-field {
      > label {
        margin-left: 12px;
      }
      > div {
        font-size: 26px;
        padding: 0 12px;
      }
    }
    .description-field {
      > div {
        padding: 27px 12px 10px;
      }
    }
    .log-level {
      width: 100%;
      display: flex;
      flex-direction: row;

      .tooltip {
        margin-left: 10px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
    }
    .log-level-field {
      width: 100%;
      > div {
        padding: 5px 12px 0;
      }
    }
    .submit-properties-button {
      margin-top: 20px;
      height: 50%;
    }
  }
}
