.base-modal-dialog {
  .MuiBackdrop-root {
    opacity: 0 !important;
  }
  &__title {
    background-color: var(--greyscale1);
    padding-bottom: 0;
    h2 {
      border-bottom: 1px solid var(--greyscale2);
      color: var(--greyscale7);
      letter-spacing: 0.9px;
      font-weight: 100;
      font-family: 'Proxima Nova';
      font-size: 20px;
      padding: 0.5em 0 0 0;
    }
  }
  &__content_container {
    outline: none;
  }
  &__button_container {
    border-top: 1px solid var(--greyscale1);
    padding: 1em 2.25em;
  }
}
