.deploy-action-button {
  color: var(--black);
  display: flex;
  width: 48px;
  height: 48px;
  padding: 0;
  align-items: center;
  justify-items: center;

  &__icon--warning {
    color: var(--uiRed1);
  }

  &deploy-action-button__tooltip {
    transform: translateY(25%);
  }
}
