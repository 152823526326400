.deployment-data-drift {
  .classification-result {
    .inference-grid-container {
      overflow: visible;
      .view-grid {
        flex: 1 0;
      }
    }
  }
}
