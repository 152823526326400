.prod-deploy-dashboard {
  flex: 1;
  min-width: 0;
  padding: 2em;

  &__menu {
    font-size: 1rem;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 10px;
    margin-bottom: 32px;

    & > button {
      font-size: inherit;
      padding: 10px 16px;
    }
  }

  &__main {
    padding-bottom: 32px;

    & > h3 {
      font-weight: 600;
      font-size: 1.5rem;
      text-align: center;
    }
  }

  &__back {
    background-color: var(--white);
    color: var(--greyscale7);
    border: 1px solid var(--greyscale3);
    box-shadow: var(--shadow2);
  }

  &__deployments {
    & thead {
      & th {
        border-bottom-style: none;
        background-color: var(--white);
      }

      & > tr:nth-child(1) th {
        font-weight: 600;
      }

      & > tr:nth-child(2) th {
        line-height: 1;
        font-size: 0.75rem;
        padding-top: 0;
        padding-bottom: 10px;
        color: var(--greyscale4b);
      }
    }

    & tbody > tr:nth-child(2n + 1) {
      background-color: var(--greyscale1);
    }
  }

  &__deployments &__row-group {
    background-color: var(--greyscale1);

    // increase specificity to overwrite defaults
    &.prod-deploy-dashboard__row-group--odd tr {
      background-color: var(--white);
    }

    & tr:not(:last-child) td {
      border: none;
    }
  }

  // increase specificity to handle odd row case
  &__deployments &__row.prod-deploy-dashboard__row--error {
    background-color: #ffe1e1;
  }

  &__pagination {
    margin: 16px 0;
    display: flex;
    justify-content: center;
  }

  &__tabs {
    margin-right: auto;
  }

  &__search {
    width: 250px;

    & .MuiInput-root {
      margin: 0;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 25px;

    &__empty {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__copy-link {
    max-width: 400px;
  }

  &__no-results {
    text-align: center;
    width: 100%;
  }
}
