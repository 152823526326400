.download-details-dialog {
  & .MuiDialog-paper {
    box-sizing: border-box;
    min-width: 25em;
    width: 90vw;
    background-color: var(--greyscale1);
    padding: 3em;
  }

  @media screen and (min-width: 1024px) {
    & .MuiDialog-paper {
      width: 80vw;
    }
  }

  p {
    font-weight: 100;
  }

  &__title {
    border-bottom: 1px solid #dfdfe0;
    padding-bottom: 0.5em;
    margin-bottom: 0;
    margin-top: 0;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    background-color: var(--white);
    padding: 1em;
    margin-left: -3em;
    margin-right: -3em;
    margin-bottom: -3em;
    margin-top: 2em;

    & > * {
      margin-left: 1em;
    }
  }
}
