.integrations {
  $cell-padding: 0.5em 1em;

  font-size: 1rem;
  &__header-cell {
    border: none;
    font-size: 0.875em;
    padding: $cell-padding;
  }

  &__body-row {
    color: var(--white);
  }

  &__body-cell {
    font-size: 1em;
    padding: $cell-padding;
  }

  &__status {
    color: var(--uiGreen2);
    font-weight: bold;

    &--in-progress {
      color: var(--secondary2);
    }
  }

  &__action {
    width: 2em;
    background-color: var(--primary1);

    &--edit {
      background-color: var(--secondary2);
    }
  }

  & .integrations__caption {
    text-align: center;
  }
}
