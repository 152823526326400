.stage-step {
  z-index: 1;
  flex: 0;
  display: flex;
  align-items: stretch;
  transition: all 0.5s ease;
  color: #333333;
  border: 0;
  min-width: 100px;
}

.step-active-cursor {
  cursor: pointer;
}

.stage-line-first-n {
  background-image: url('../../../assets/images/stageline.png');
  background-position: right 0 bottom 23px;
  background-repeat: no-repeat;
}

.stage-line-first-f {
  background-image: url('../../../assets/images/stageline-f.png');
  background-position: right 0 bottom 23px;
  background-repeat: no-repeat;
}

.stage-line-last-n {
  background-image: url('../../../assets/images/stageline.png');
  background-position: left 0 bottom 23px;
  background-repeat: no-repeat;
}

.stage-line-last-f {
  background-image: url('../../../assets/images/stageline-f.png');
  background-position: left 0 bottom 23px;
  background-repeat: no-repeat;
}

.stage-line-nn {
  background-image: url('../../../assets/images/stageline.png');
  background-position: left 0 bottom 23px;
  background-repeat: repeat-x;
}

.stage-line-ff {
  background-image: url('../../../assets/images/stageline-f.png');
  background-position: left 0 bottom 23px;
  background-repeat: repeat-x;
}

.stage-line-nf {
  background-image: url('../../../assets/images/stageline-nf.png');
  background-position: left 0 bottom 23px;
  background-repeat: no-repeat;
}

.stage-line-fn {
  background-image: url('../../../assets/images/stageline-fn.png');
  background-position: left 0 bottom 23px;
  background-repeat: no-repeat;
}

.stage-step div.section-title {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 90px;
  min-width: 90px;
  margin: 0;
  padding: 0;
  text-align: center;
  word-spacing: 80px;
  font-size: 16px;
  transition: all 0.25s ease;
}

.stage-step.active {
  cursor: default;
}

.stage-step.active div.section-title {
  opacity: 1;
  transform: scaleX(1);
  font-weight: bold;
}

.stage-step div.section-filler {
  width: 100%;
  min-width: 5px;
  max-width: 165px;
}

.stage-step.active div.section-filler {
  flex: 2;
}

.section-text {
  height: 95px;
  justify-content: center;
  padding-top: 38px;
}

.section-button {
  min-height: 50px;
  max-height: 50px;
  width: 50px;
  border-radius: 50%;

  box-sizing: border-box;
  box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.1);

  transition: all 0.25s ease;
}

.section-button span.section-checkmark {
  display: none;
  animation: stage-bullet-disappear 0.25s ease;
}

.section-button .section-locked-icon {
  display: none;
  animation: stage-bullet-disappear 0.25s ease;
}

.section-button span.section-bullet {
  display: none;
  transition: all 0.25s ease;
  animation: stage-bullet-disappear 0.25s ease;
}

.section-button div.waiting-block {
  display: none;
  animation: stage-bullet-disappear 0.25s ease;
}

.section-button-inactive span.section-bullet {
  transition: all 0.25s ease;
  animation: stage-bullet-disappear 0.25s ease;
}

.section-button-finished {
  font-family: Material-Design-Iconic-Font, serif;
  font-size: 22px;
  color: white;
  font-weight: normal;
  text-indent: 1px;
  line-height: 49px;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  background: linear-gradient(137.68deg, #212d66 0%, #425acc 100%);
}

.section-button-finished span.section-checkmark {
  display: inline;
  animation: stage-bullet-appear 0.25s ease;
}

.section-button-in-progress {
  background-color: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
}

.section-button-in-progress div.waiting-block {
  display: flex;
  margin-top: 10px;
  animation: stage-bullet-appear 0.25s ease;
}

.section-button-locked {
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  font-family: Material-Design-Iconic-Font, serif;
  font-size: 22px;
  color: #b2b2b2;
  font-weight: normal;
  line-height: 46px;
}

.section-button-locked .section-locked-icon {
  display: inline;
}

.section-button-inactive {
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  line-height: 49px;
}

.section-button-inactive span.section-bullet {
  display: inline-block;
  min-height: 10px;
  max-height: 10px;
  width: 10px;
  border-radius: 50%;
  background: linear-gradient(137.68deg, #212d66 0%, #425acc 100%);
  animation: stage-bullet-appear 0.25s ease;
}

.stage-step.active div.section-button-inactive {
  background-color: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  line-height: 59px;
}

.stage-step.active .section-button.section-button-locked span.section-bullet {
  display: none;
}

.stage-step.active span.section-bullet {
  display: inline-block;
  min-height: 20px;
  max-height: 20px;
  width: 20px;
  animation: stage-bullet-appear 0.25s ease;
}

.stage-step.active div.section-button-in-progress span.section-bullet {
  display: none;
  animation: stage-bullet-disappear 0.25s ease;
}

.stage-step.active div.section-button-finished span.section-bullet {
  display: none;
  animation: stage-bullet-disappear 0.25s ease;
}

@keyframes stage-bullet-appear {
  0% {
    opacity: 0;
  }
}

@keyframes stage-bullet-disappear {
  0% {
    opacity: 1;
  }
}
