.flow-graph {
  background-image: url('../../assets/images/ruler.png');

  &__action_buttons {
    z-index: 2;
    position: absolute;
    left: 300px;
    top: 70px;
  }
  &__exit_button {
    z-index: 2;
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 16px;
    height: 48px;
    width: 232px;
    border-radius: 4px;
    box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.1);
  }
  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

// TODO: Need to move this to a global setting
.MuiSnackbar-root {
  & > .MuiAlert-root {
    color: white;
    font-family: 'Proxima Nova', 'Segoe UI Light', 'Segoe UI', 'Helvetica Neue Light',
      'Helvetica Neue', Helvetica, Verdana, sans-serif;
    font-size: 14px;
    font-weight: bold;
    border-radius: 0.4rem;

    & > .MuiAlert-icon {
      color: white;
    }
    &.MuiAlert-standardError {
      background: rgb(221, 78, 78);
      background: linear-gradient(
        90deg,
        rgba(221, 78, 78, 1) 0%,
        rgba(171, 60, 60, 1) 100%
      );
    }
    &.MuiAlert-standardSuccess {
      background: rgb(33, 45, 102);
      background: linear-gradient(
        90deg,
        rgba(33, 45, 102, 1) 0%,
        rgba(66, 90, 204, 1) 100%
      );
    }
    &.MuiAlert-standardWarning {
      background: rgb(223, 197, 86);
      background: linear-gradient(
        90deg,
        rgba(223, 197, 86, 1) 0%,
        rgba(162, 135, 48, 1) 100%
      );
    }
  }
}
