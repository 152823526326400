.help {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 65px;
  z-index: 10000;
  background-color: white;
  background-image: url('../../assets/images/dashboard__assistant-corner.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 190px auto;

  &__exit_button {
    right: 20px;
    top: 20px;
    box-shadow: var(--shadow2);
    position: absolute;
  }
}
