.image-card-detail {
  padding: 0 5px;
  align-items: center;
  display: flex;

  &__title {
    flex-grow: 1;
    user-select: none;
  }

  &:hover &__title {
    font-weight: bold;
  }

  &__progress {
    flex-basis: 50px;
    flex-shrink: 0;

    > * {
      margin: 0 0.5em;
    }
  }

  &__stats {
    flex-basis: 35px;
    flex-shrink: 0;
  }
}
