.import-model-panel {
  background: url('../../../assets/images/model-icon_active.svg') no-repeat 50% 30px;
}

.import-model-panel.in-progress-content {
  background: url('../../../assets/images/model-icon_active.svg') no-repeat 50% 40px;
  overflow: visible;
}

.panel-import-model-active {
  flex: 1;
}
.panel-import-model-active:hover {
  cursor: pointer;
}

.import-model-in-progress-indicator {
  width: 130px;
  height: 6px;
  border-radius: 3px;
  background: linear-gradient(to right, #6cccde, #1a8bca 50%, #3e499e);
  margin: 40px 0 0 116px;
  animation: slide-indicator 2.1s infinite ease;
}

#IMPORT_MODEL-panel-content {
  overflow: visible;
}

@keyframes slide-indicator {
  0% {
    margin-top: 40px;
  }
  50% {
    margin-top: 130px;
  }
  100% {
    margin-top: 40px;
  }
}

.retrain-form .stage-assistant-form__actions {
  margin-top: 0;
}

.model-panel__action {
  flex: 1;
  justify-content: center;
}

.model-panel__body {
  padding: 0 30px;
}
