.toaster {
  background-color: white;
  font-family: 'Proxima Nova', 'Segoe UI Light', 'Segoe UI', 'Helvetica Neue Light',
    'Helvetica Neue', Helvetica, Verdana, sans-serif;
  font-size: 14px;
  color: white;
  overflow: hidden;
  max-width: 650px;
  position: relative;
  border-radius: 0.4rem;
  display: flex;
  padding: 1rem 1rem 0.7rem 1rem;
  margin-bottom: 1.5rem;
  margin-right: 1rem;

  box-shadow: rgba(52, 58, 64, 0.15) 0px 1px 10px 0px,
    rgba(52, 58, 64, 0.1) 0px 6px 12px 0px, rgba(52, 58, 64, 0.12) 0px 6px 15px -2px;

  &.toaster-info {
    background: rgb(76, 76, 76);
  }
  &.toaster-error {
    background: rgb(221, 78, 78);
    background: linear-gradient(
      90deg,
      rgba(221, 78, 78, 1) 0%,
      rgba(171, 60, 60, 1) 100%
    );
  }
  &.toaster-success {
    background: rgb(33, 45, 102);
    background: linear-gradient(
      90deg,
      rgba(33, 45, 102, 1) 0%,
      rgba(66, 90, 204, 1) 100%
    );
  }
  &.toaster-warn {
    background: rgb(223, 197, 86);
    background: linear-gradient(
      90deg,
      rgba(223, 197, 86, 1) 0%,
      rgba(162, 135, 48, 1) 100%
    );
  }
  &.icon > div {
    padding-right: 1.25rem;
  }
  & > * {
    padding: 0.5rem;
  }
  & > .toaster-action {
    display: inherit;
    padding-left: 1rem;
  }
  & > .toaster-action > button {
    margin-left: 1rem;
    background: var(--elementDarkHover);
  }
  & > .toaster-action > button {
    height: 28px;
  }
}

.Toaster__manager-top {
  top: 20px !important;
}
