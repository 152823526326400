@keyframes scale {
  0% {
    transform: scale(1);
    fill: #00c2de;
  }

  30% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.3);
  }

  50% {
    transform: scale(1.5);
    left: 50px;
    fill: #007bb7;
  }

  60% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
    fill: #00c2de;
  }
}

.waiting #_1 {
  -webkit-animation: scale 0.8s infinite 0.7s ease-in-out,
    fill 0.8s infinite 0.7s ease-in-out;
  -moz-animation: scale 0.8s infinite 0.7s ease-in-out,
    fill 0.8s infinite 0.7s ease-in-out;
  -o-animation: scale 0.8s infinite 0.7s ease-in-out, fill 0.8s infinite 0.7s ease-in-out;
  -ms-animation: scale 0.8s infinite 0.7s ease-in-out, fill 0.8s infinite 0.7s ease-in-out;
  animation: scale 0.8s infinite 0.7s ease-in-out, fill 0.8s infinite 0.7s ease-in-out;
}

.waiting #_2 {
  -webkit-animation: scale 0.8s infinite 0.6s ease-in-out,
    fill 0.8s infinite 0.6s ease-in-out;
  -moz-animation: scale 0.8s infinite 0.6s ease-in-out,
    fill 0.8s infinite 0.6s ease-in-out;
  -o-animation: scale 0.8s infinite 0.6s ease-in-out, fill 0.8s infinite 0.6s ease-in-out;
  -ms-animation: scale 0.8s infinite 0.6s ease-in-out, fill 0.8s infinite 0.6s ease-in-out;
  animation: scale 0.8s infinite 0.6s ease-in-out, fill 0.8s infinite 0.6s ease-in-out;
}

.waiting #_3 {
  -webkit-animation: scale 0.8s infinite 0.5s ease-in-out,
    fill 0.8s infinite 0.5s ease-in-out;
  -moz-animation: scale 0.8s infinite 0.5s ease-in-out,
    fill 0.8s infinite 0.5s ease-in-out;
  -o-animation: scale 0.8s infinite 0.5s ease-in-out, fill 0.8s infinite 0.5s ease-in-out;
  -ms-animation: scale 0.8s infinite 0.5s ease-in-out, fill 0.8s infinite 0.5s ease-in-out;
  animation: scale 0.8s infinite 0.5s ease-in-out, fill 0.8s infinite 0.5s ease-in-out;
}

.waiting #_4 {
  -webkit-animation: scale 0.8s infinite 0.4s ease-in-out,
    fill 0.8s infinite 0.4s ease-in-out;
  -moz-animation: scale 0.8s infinite 0.4s ease-in-out,
    fill 0.8s infinite 0.4s ease-in-out;
  -o-animation: scale 0.8s infinite 0.4s ease-in-out, fill 0.8s infinite 0.4s ease-in-out;
  -ms-animation: scale 0.8s infinite 0.4s ease-in-out, fill 0.8s infinite 0.4s ease-in-out;
  animation: scale 0.8s infinite 0.4s ease-in-out, fill 0.8s infinite 0.4s ease-in-out;
}

.waiting #_5 {
  -webkit-animation: scale 0.8s infinite 0.3s ease-in-out,
    fill 0.8s infinite 0.3s ease-in-out;
  -moz-animation: scale 0.8s infinite 0.3s ease-in-out,
    fill 0.8s infinite 0.3s ease-in-out;
  -o-animation: scale 0.8s infinite 0.3s ease-in-out, fill 0.8s infinite 0.3s ease-in-out;
  -ms-animation: scale 0.8s infinite 0.3s ease-in-out, fill 0.8s infinite 0.3s ease-in-out;
  animation: scale 0.8s infinite 0.3s ease-in-out, fill 0.8s infinite 0.3s ease-in-out;
}

.waiting #_6 {
  -webkit-animation: scale 0.8s infinite 0.2s ease-in-out,
    fill 0.8s infinite 0.2s ease-in-out;
  -moz-animation: scale 0.8s infinite 0.2s ease-in-out,
    fill 0.8s infinite 0.2s ease-in-out;
  -o-animation: scale 0.8s infinite 0.2s ease-in-out, fill 0.8s infinite 0.2s ease-in-out;
  -ms-animation: scale 0.8s infinite 0.2s ease-in-out, fill 0.8s infinite 0.2s ease-in-out;
  animation: scale 0.8s infinite 0.2s ease-in-out, fill 0.8s infinite 0.2s ease-in-out;
}

.waiting #_7 {
  -webkit-animation: scale 0.8s infinite 0.1s ease-in-out,
    fill 0.8s infinite 0.1s ease-in-out;
  -moz-animation: scale 0.8s infinite 0.1s ease-in-out,
    fill 0.8s infinite 0.1s ease-in-out;
  -o-animation: scale 0.8s infinite 0.1s ease-in-out, fill 0.8s infinite 0.1s ease-in-out;
  -ms-animation: scale 0.8s infinite 0.1s ease-in-out, fill 0.8s infinite 0.1s ease-in-out;
  animation: scale 0.8s infinite 0.1s ease-in-out, fill 0.8s infinite 0.1s ease-in-out;
}

.waiting #_8 {
  -webkit-animation: scale 0.8s infinite 0s ease-in-out, fill 0.8s infinite 0s ease-in-out;
  -moz-animation: scale 0.8s infinite 0s ease-in-out, fill 0.8s infinite 0s ease-in-out;
  -o-animation: scale 0.8s infinite 0s ease-in-out, fill 0.8s infinite 0s ease-in-out;
  -ms-animation: scale 0.8s infinite 0s ease-in-out, fill 0.8s infinite 0s ease-in-out;
  animation: scale 0.8s infinite 0s ease-in-out, fill 0.8s infinite 0s ease-in-out;
}

.waiting-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.waiting-block .waiting-text {
  color: #666666;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
}
