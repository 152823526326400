.grid-viewer {
  &__images {
    width: 100%;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__item {
    margin: 0.5em;
  }
}
