.conversation-assistant {
  &__drawer {
    z-index: 0;

    &--open {
      min-width: 15em;
      max-width: 30em;
      width: 40vw;
    }

    .MuiDrawer-paper {
      padding: 0;
      min-width: 15em;
      max-width: 30em;
      width: 40vw;
      background-image: url('../../assets/images/dashboard__assistant.png'),
        url('../../assets/images/dashboard__assistant-corner.png');
      background-position: top right, top left;
      background-repeat: no-repeat, no-repeat;
      background-size: auto 100%, 40% auto;

      // Darker version of assistant background used for drawer version. Opacity initially 0.
      &::after {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
        content: '';
        background-image: url('../../assets/images/dashboard__assistant.png');
        background-position: top right;
        background-repeat: no-repeat;
        background-size: auto 100%;
        opacity: 0;
      }

      // Gradient background used with drawer version. Opacity initially 0.
      &::before {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
        content: '';
        background-image: linear-gradient(
          180deg,
          rgba(22, 187, 221, 1) 0%,
          rgba(43, 120, 178, 1) 50%,
          rgba(57, 67, 144, 1) 100%
        );
        opacity: 0;
        transition: opacity 0.1s ease-in-out;
      }
    }

    &--drawer {
      .MuiDrawer-paper {
        padding-top: 3em;
        &:before {
          opacity: 1;
        }

        &::after {
          opacity: 0.3;
        }
      }
    }
  }

  &__icon {
    position: absolute;
    bottom: 35px;
    left: 20px;
    height: 80px;
    width: 80px;
    color: black;
    transition: left 0.5s;
    transition-timing-function: cubic-bezier;
    z-index: var(--drawer-z-index);

    &--drawer {
      cursor: pointer;
    }
  }

  &__refresh-btn {
    position: absolute;
    bottom: 48px;
    left: 120px;
    height: 48px;
    width: 48px;
    min-width: initial;
    border-radius: 24px;
    border: 1px solid var(--greyscale3);
    background-color: var(--white);
    box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.1);
    z-index: var(--drawer-z-index);
    padding: 0;

    &:hover {
      background-color: var(--white);
      opacity: 0.8;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 0 2em;

    // TODO: Remove after L3 is fully supported
    &--reverse {
      flex-direction: column-reverse;
    }
  }

  // TODO: Remove after L3 is fully supported
  &__message {
    &-enter {
      opacity: 0;
    }

    &-enter-active {
      opacity: 1;
      transition: opacity 0.5s ease-in;
    }
  }

  &__scroll-container {
    margin-bottom: 7em;
  }
}

.scroll-to-bottom {
  overflow: auto;
  height: 100%;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100%;
  }
}
