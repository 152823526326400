.data-viewer-label {
  margin: auto 0;

  &__label {
    font-weight: 600;
  }

  &__title {
    color: var(--greyscale7);
    font-size: 20px;
    line-height: 1.6;
  }
}
