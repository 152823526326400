@import '../StudioTextField/shared';

.studio-autocomplete-field {
  @include MuiInputOverrides;
  input.MuiInput-input {
    height: 1.5em !important;
    padding: 12px 16px !important;
  }
  .MuiInput-underline:before {
    display: none;
  }
}
