.deployment-panel {
  background: url('../../../assets/images/deploy-icon_active.svg') no-repeat 52% 30px;

  &__finished-container {
    border: 1px dashed #dcdcdc;
    border-radius: 8px;
    text-align: center;
    height: 222px;
    opacity: 1;
    margin: 8px;
  }

  &__finished-container .form-button {
    margin-top: 158px;
    width: 250px;
  }
}
.panel-deployment-active {
  flex: 1;
}
.deployment-panel-in-progress {
  background: url('../../../assets/images/deploy-icon_active.svg') no-repeat 52% 30px;
}

.deployment-in-progress-indicator {
  width: 130px;
  height: 6px;
  border-radius: 3px;
  background: linear-gradient(to right, #6cccde, #1a8bca 50%, #3e499e);
  margin: 40px 0 0 116px;
  animation: slide-indicator 2.1s infinite ease;
}
