.test-deploy-view {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: white;

  .assistant-icon {
    visibility: hidden;
    display: none;
  }
}

.test-deploy-view__template {
  display: grid;
  grid-gap: 0.5em;
  height: 100%;
  overflow: hidden;
  padding: 0.5em;
}

.test-deploy-view__template--sidebar {
  grid-template-columns: 320px auto;
  grid-template-rows: min-content auto;
  grid-template-areas:
    'description description'
    'sidebar sidebar';
  .test-deploy-view__tabs,
  .test-deploy-view__results {
    display: none;
  }

  @media (min-width: 640px) {
    grid-template-areas:
      'description tabs'
      'sidebar results';
    .test-deploy-view__tabs,
    .test-deploy-view__results {
      display: flex;
    }
  }
}

.test-deploy-view__template--no-sidebar {
  grid-template-columns: 320px auto;
  grid-template-rows: min-content min-content auto;
  grid-template-areas:
    'description description'
    'tabs tabs'
    'results results';
  .test-deploy-view__tabs,
  .test-deploy-view__results {
    display: flex;
  }
}

.test-deploy-view__tabs {
  grid-area: tabs;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 0;
  .MuiTabs-indicator {
    height: 1px;
  }
}

.test-deploy-view__download {
  border: 1px solid var(--greyscale3);
  color: var(--greyscale6);
  background-color: var(--white);
  box-shadow: var(--shadow2);
  padding: 6px;
  margin: 0 0.5em;
}

.test-deploy-view__tab {
  font-size: 16px;
  line-height: 1.5;
  padding-bottom: 0;
  text-transform: uppercase;
  &.Mui-selected {
    font-weight: bold;
  }
}
.test-deploy-view__tab-panel:not([hidden]) {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  position: relative;
}
.test-deploy-view__test-description {
  grid-area: description;
}
.test-deploy-view__deploy-config-panel {
  grid-area: sidebar;
}
.test-deploy-view__results {
  grid-area: results;
  overflow: hidden;
}
.test-deploy-view__details {
  padding: 0.5em 1em;
  overflow: auto;
  width: 100%;
}
.dplmt-export-form {
  width: 325px;
}
.dplmt-export-field {
  margin: 0.5em 0.75em;
  width: 100%;
}
.dplmt-export-title {
  display: flex;
  justify-content: center;
}
.dplmt-export-title-top {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dplmt-export-title-text {
  margin-left: auto;
}

.dplmt-delete-app-form {
  width: 325px;
}
.dplmt-delete-app-field {
  margin: 0.5em 0.75em;
  width: 100%;
}
.dplmt-delete-app-title {
  display: flex;
  justify-content: center;
}
.dplmt-delete-app-title-top {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dplmt-delete-app-title-text {
  margin-left: auto;
}
