.project-list {
  height: 100%;
  width: 100%;
}

.project-list__container {
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  min-height: 100%;
  padding: 0 10%;
  display: block;
  flex-flow: column nowrap;
  align-items: center;
  padding-bottom: 120px;
  overflow-y: scroll;
}

.project-list__header {
  display: flex;
  align-items: flex-end;
  margin: 1em 0 1.5em;

  & * + * {
    margin-left: 0.5em;
  }
}

/* PROJECT LIST VIEW */
.project-list__view-list {
  margin: 1.5em 0;
  flex-flow: column nowrap;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}
.project-list__view-list .MuiGrid-item {
  width: 100%;
  flex: 1 0;
  margin-bottom: 32px;
}
.project-list__view-list .MuiGrid-item > .project-button {
  min-width: 100%;
  margin: 0;
}

/* PROJECT GRID VIEW */
.project-list__view-grid {
  flex: 1 1;
  display: flex;
  flex-flow: row wrap;
  justify-items: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-around;
}
.project-list__view-grid .project-grid-card-container {
  margin-bottom: 24px;
}

.project-list__tile {
  width: 328px;
  height: 368px;
  margin: 8px;
}

.project-list__status--hidden {
  visibility: hidden;
}

.project-list__empty-message {
  text-align: center;
  color: #656565;
  font-size: 1.5em;
  margin-top: 1em;
}

.project-list__search {
  display: flex;
  margin-right: auto;
  align-items: flex-end;
}

.project-list__loading--append {
  top: auto;
}
