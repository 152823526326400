.stage-line-area {
  z-index: 1;
  position: relative;
  height: 145px;
  display: flex;
  margin: 0;
  padding: 0;
  overflow: visible;
  list-style-type: none;
  justify-content: flex-end;
}
