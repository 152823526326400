.flowgraph {
  &__loading_container {
    & > * {
      margin-bottom: 1em;
    }
  }
  &__module_container {
    width: 230px;
    max-width: 230px;
    position: absolute;
    top: 140px;
    bottom: 40px;
    left: 20px;
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background: var(--greyscale1);
  }
  &__module_list {
    overflow-y: auto;
    .module-button {
      margin: 0.3em;
      button {
        border-color: rgba(0, 0, 0, 0.1);
      }
    }
    &_container {
      width: 100%;
    }
    & .MuiExpansionPanelSummary-root {
      padding: 0;
    }
    &_expansion {
      background: none;
      &::before {
        height: 0;
      }
    }
    &_summary {
      height: 65px;
      max-height: 65px;
      flex-direction: row-reverse;
      color: var(--greyscale5);
      .MuiExpansionPanelSummary-content {
        flex-direction: column;
      }
      p {
        font-size: 0.9rem;
        font-weight: 500;
        padding-right: 8px;
      }
      .MuiExpansionPanelSummary-expandIcon {
        margin: 0;
      }
    }
    &_detail {
      width: 100%;
    }
  }
}
