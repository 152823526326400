.settings-container {
  width: 100%;
  height: 100%;
  background-color: var(--greyscale1);
}

.settings-content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  background-color: var(--greyscale1);
}

.settings__return {
  display: block;
  // TODO : revert this back after restructuring the help
  // margin: 1em 1em 1em auto;
  margin: 0.5em 0.5em 0.5em auto;
}

.settings__tabs {
  margin-top: 5em;
}

.settings__tab {
  min-width: 7em;
  font-size: 2rem;
  padding: 0.5em 1.5em;
}

.settings-options {
  margin-top: 4em;

  &-body {
    margin-bottom: 10px;
  }
}

.login-button {
  position: relative;
  width: 320px;
  display: block;
}

.logout-button {
  position: relative;
  width: 320px;
  display: block;
  top: 30px;
}

.additional_info_container {
  background-color: white;
  border: 1px solid var(--greyscale3);
  height: 200px;
  padding: 15px;
  overflow-y: scroll;
  margin-top: 5px;

  &_show {
    display: block;
  }

  &_hide {
    display: none;
  }
}

.additional-info-head {
  display: flex;
  flex-direction: row;
  row-gap: 10px;
  align-items: center;
}
