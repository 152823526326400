.signup__tooltip {
  text-align: center;
}

.signup__tooltip-text {
  color: var(--white);
  font-size: 14px;
  line-height: 1em;
}

.signup__tooltip-text--fade {
  color: var(--greyscale4);
}

.signup__domain-description--hidden {
  display: none;
}
